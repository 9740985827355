import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";

const UserContext = React.createContext();

const useUser = () => {
  const c = useContext(UserContext);
  if (!c) throw new Error("useUser must be used in UserProvider");
  return c;
};

const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const _checkToken = async (userFromToken) => {
    try {
      const authRes = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/2/auth`,
        {
          jwt: userFromToken?.jwt,
          email: userFromToken?.email,
        }
      );
      if (authRes.data.success) {
        const decodedJwt = jwt_decode(userFromToken?.jwt);
        const newUser = { ...userFromToken, ...decodedJwt };
        localStorage.setItem("_token", window.btoa(JSON.stringify(newUser)));
        updateUser(newUser);
        return;
      }
      throw new Error("Bad token");
    } catch (err) {
      signOut();
      console.log(err); // eslint-disable-line no-console
    }
  };

  const _authorizeUser = async () => {
    try {
      document.title = "Dashboard - Zeal Innovations";
      const searchParams = new URL(window.location).searchParams;
      let authUser = searchParams.get("token");
      let storageUser = localStorage.getItem("_token");
      if (authUser) {
        // get the data which was redirected from auth server
        authUser = decodeURIComponent(authUser);
        authUser = window.atob(authUser);
        authUser = JSON.parse(authUser);
        authUser.account = authUser.account_id;
        await _checkToken(authUser);
        return;
      }
      if (storageUser) {
        storageUser = JSON.parse(window.atob(storageUser));
        await _checkToken(storageUser);
        return;
      }
      throw new Error("login error");
    } catch (error) {
      console.log({ error }); // eslint-disable-line no-console
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    _authorizeUser();
  }, []); // eslint-disable-line

  const signOut = () => {
    console.log("signing out"); // eslint-disable-line no-console
    setUser(null);
    localStorage.removeItem("_token");
    window.location.href = `${process.env.REACT_APP_AUTH_SERVER_URL}/?system=zi`;
  };

  const updateUser = (user) => {
    let activeAccount;
    if (user?.accounts) {
      activeAccount = user.accounts.find(
        (account) => account.account_id === user.account_id
      );
    }

    const newUser = {
      ...user,
      ...activeAccount,
    };

    axios.defaults.headers.common.Authorization = `Bearer ${newUser?.jwt}`;
    localStorage.setItem("_token", window.btoa(JSON.stringify(newUser)));
    setUser(newUser);
  };

  if (isLoading === true) {
    return null;
  }

  if (isLoading === false && user) {
    return (
      <UserContext.Provider value={{ user, setUser, updateUser, signOut }}>
        {children}
      </UserContext.Provider>
    );
  }

  return signOut();
};

UserProvider.propTypes = {
  children: PropTypes.any,
};
UserProvider.defaultProps = {
  children: undefined,
};
export default UserContext;
export { useUser, UserProvider };
