import React from "react";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import { CloseIcon, SaveIcon } from "../svg";

const UpdatePaymentMethodModal = (props) => {
  const { target, initialData, isSubmitting, onSubmit } = props;
  const { expiration_month, expiration_year, zip, last_4 } = initialData;

  return (
    <ActionModal target={target} title="Edit Payment Method">
      <form
        className="modal-body fw-500"
        id="update-payment-method-form"
        onSubmit={onSubmit}
      >
        <div className="row g-3 g-md-5">
          <div className="col-12">
            <small className="text-muted float-end">* Required</small>
          </div>
          <div className="col-12 col-md-6 mt-md-2">
            <label htmlFor="cc" className="form-label">
              Card Number*
            </label>
            <input
              type="text"
              className="form-control"
              id="cc"
              name="cc"
              required
              defaultValue={last_4}
            />
          </div>
          <div className="col-12 col-md-6 mt-md-2">
            <label htmlFor="cvv" className="form-label">
              CVC*
            </label>
            <input
              type="number"
              className="form-control"
              id="cvv"
              name="cvv"
              required
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="expired_date" className="form-label">
              Expiration Date*
            </label>
            <input
              type="text"
              className="form-control"
              id="expired_date"
              name="expired_date"
              defaultValue={`${expiration_month}/${expiration_year}`}
              placeholder="MM / YYYY"
              required
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="zip" className="form-label">
              Billing Zip
            </label>
            <input
              type="text"
              className="form-control"
              id="zip"
              name="zip"
              defaultValue={zip}
            />
          </div>
        </div>
      </form>
      <div className="modal-footer">
        <button
          type="button"
          data-bs-dismiss="modal"
          data-bs-target={`#${target}-modal`}
          aria-label="Close"
          className="btn btn-sm btn-outline-light d-flex align-items-center"
          disabled={isSubmitting}
        >
          <CloseIcon width={12} height={12} />
          <span className="ms-2">Cancel</span>
        </button>
        <button
          type="submit"
          form="update-payment-method-form"
          className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div
              className="spinner-border spinner-border-sm text-white"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <SaveIcon width={12} height={12} />
          )}
          <span className="ms-2">Submit</span>
        </button>
      </div>
    </ActionModal>
  );
};
UpdatePaymentMethodModal.propTypes = {
  target: PropTypes.string.isRequired,
  initialData: PropTypes.objectOf(PropTypes.any).isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
UpdatePaymentMethodModal.defaultProps = {
  isSubmitting: false,
};
export default UpdatePaymentMethodModal;
