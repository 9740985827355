import React from "react";
import PropTypes from "prop-types";

const ProductPriceLabel = (props) => {
  const { product } = props;
  if (!product?.price) {
    return <label>FREE</label>;
  }
  return (
    <label className="text-lowercase fw-semibold">
      ${product?.price}/{product?.frequency.slice(0, -2)}
    </label>
  );
};

ProductPriceLabel.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductPriceLabel;
