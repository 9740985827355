import React from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { HelpIcon, PlusIcon } from "components/svg";
import PropTypes from "prop-types";

function OverageCredits(props) {
  const { target, currentOverage } = props;
  const isNegativeOverage = currentOverage < 0;

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex pe-5">
        <span
          className={`align-self-end ${
            isNegativeOverage ? "text-danger" : "text-primary"
          }`}
        >
          {currentOverage} GB
        </span>
        <small className="align-self-end text-muted fs-8 ps-1 pe-2">
          Overage Credits
        </small>
        <span id="overage-credit-help">
          <HelpIcon width={20} height={20} />
        </span>
          <UncontrolledPopover
            placement="bottom"
            offset={[-100, 15]}
            trigger="hover"
            target={"#overage-credit-help"}
            className="overages_pop"
          >
            <PopoverBody
              className="shadow fs-8 fw-500 p-3"
              style={{ lineHeight: "20px", width: 261, fontWeight: 400}}
            >
              Overage credits are a fail-safe so that if you go over your monthly
              plan allotment of bandwidth, your account does not become
              suspended due to your overages.{" "}
              <a href="https://zealinnovations.freshdesk.com/support/solutions/articles/150000064458-prepaid-overages" target="_blank" rel="noreferrer" className="text-reset text-decoration-underline">
                Find out more.
              </a>
            </PopoverBody>
          </UncontrolledPopover>
      </div>
      <button
        type="button"
        className="btn btn-sm btn-outline-light"
        data-bs-toggle="modal"
        data-bs-target={`#${target}`}
      >
        <PlusIcon width={12} height={12} />
        <span className="ps-1">Credits</span>
      </button>
    </div>
  );
}

OverageCredits.propTypes = {
  target: PropTypes.string.isRequired,
  currentOverage: PropTypes.number,
};
OverageCredits.defaultProps = {
  currentOverage: 0,
};

export default OverageCredits;
