import React from "react";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="text-center text-primary py-5">
      <h1 className="display-1">404</h1>
      <h4 className="mb-5">
        <span className="text-danger">Opps! </span>Page not found
      </h4>
      <NavLink to="/" className="btn btn-primary btn-sm">
        Go Home
      </NavLink>
    </div>
  );
};

export default NotFound;
