import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SYSTEM_ROLE } from "constant";
import { useUser } from "context/User";
import About from "pages/About";
import Access from "pages/Access";
import Account from "pages/Account";
import Billing from "pages/Billing";
import NotFound from "pages/Error/404";
import User from "pages/User";
import UserDetail from "pages/User/Detail";

import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";

import RedirectIfAuthRoute from "./components/RedirectIfAuthRoute";

import "./styles/App.scss";
import "react-toastify/dist/ReactToastify.css";
import "./styles/customToast.scss";

function App() {
  const { user } = useUser();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RedirectIfAuthRoute />}>
            {user.role === SYSTEM_ROLE.ZI_ADMIN && (
              <>
                <Route index element={<Access />} />
                <Route path="access" element={<Access />} />
                <Route path="user" element={<User />} />
                <Route path="user/:email" element={<UserDetail />} />
                <Route path="billing" element={<Billing />} />
                <Route path="account" element={<Account />} />
              </>
            )}

            {user.role === SYSTEM_ROLE.ZI_USER && (
              <>
                <Route index element={<Account />} />
                <Route path="account" element={<Account />} />
              </>
            )}
          </Route>

          <Route path="/*" element={<NotFound />} />
          <Route path="about" element={<About />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        icon={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
    </>
  );
}

export default App;
