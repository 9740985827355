/* eslint-disable react/prop-types */
import React from "react";

export const SaveIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V2.66667L9.33333 0ZM10.6667 10.6667H1.33333V1.33333H8.78L10.6667 3.22V10.6667ZM6 6C4.89333 6 4 6.89333 4 8C4 9.10667 4.89333 10 6 10C7.10667 10 8 9.10667 8 8C8 6.89333 7.10667 6 6 6ZM2 2H8V4.66667H2V2Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
