/* eslint-disable react/prop-types */
import React from "react";

export const ArrowDownIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.705 1.70498L11.295 0.294983L6.70496 4.87498L2.11496 0.294982L0.704956 1.70498L6.70496 7.70498L12.705 1.70498Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
