import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import ActionModal from "components/shared/ActionModal";
import {
  ArrowRightIcon,
  BankIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  HelpIcon,
  PlusIcon,
} from "components/svg";
import { ACH_FLOW_STATUS, ACH_FLOW_TYPE, DATE_TIME_FORMAT } from "constant";
import { ACTION_TYPE, useBilling } from "context/Billing";
import { utcToLocal } from "helpers/functions";
import PropTypes from "prop-types";

function PayACHCard(props) {
  const { data, name, onEdit, onDelete, onCreate, onVerify } = props;
  const { actionType, defaultPaymentId, setDefaultPaymentMethod } =
    useBilling();

  const _renderDeleteButton = () => {
    if (onDelete && !data?.is_default) {
      return (
        <>
          <button
            className="btn btn-sm btn-outline-light"
            data-bs-toggle="modal"
            data-bs-target={"#delete-ach-confirm"}
            disabled={actionType === ACTION_TYPE.PAYMENT_ACH_DELETE}
          >
            <CloseIcon width={12} height={12} />
            <span className="ps-1">Delete</span>
          </button>
          <ActionModal
            target={"delete-ach-confirm"}
            title="Are you sure?"
            maxWidth={450}
            cancelBtnProps={{ title: "No" }}
            okBtnProps={{
              title: "Yes",
              "data-bs-toggle": undefined,
              "data-bs-dismiss": "modal",
              type: "button",
              disabled: actionType === ACTION_TYPE.PAYMENT_ACH_DELETE,
              onClick: onDelete,
            }}
          >
            <div className="modal-body fw-500">
              By clicking yes, you are confirming to delete ACH.
            </div>
          </ActionModal>
        </>
      );
    }
  };

  const _renderAchActions = () => {
    if (data?.status === ACH_FLOW_STATUS.PENDING) {
      if (data?.type === ACH_FLOW_TYPE.MANUAL_MICRO_DEPOSITS) {
        return (
          <div>
            <button
              className="btn btn-sm btn-outline-light ms-3 me-2"
              disabled={actionType === ACTION_TYPE.PAYMENT_ACH_MANUAL_VERIFY}
              onClick={onVerify}
            >
              <CheckIcon width={12} height={10} />
              <span className="ps-1">Verify</span>
            </button>
            <span id="ach-manual-pending">
              <HelpIcon width={20} height={20} />
            </span>
            <UncontrolledPopover
              placement="top"
              offset={[4, 20]}
              trigger="hover"
              target={"#ach-manual-pending"}
            >
              <PopoverBody
                className="shadow fs-8 fw-500"
                style={{ lineHeight: "18px" }}
              >
                Your account will receive a micro-deposit that you will need to
                verify in one to two business days. Once you see the
                micro-deposit, come back here to finish verifying your account.
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        );
      }
      return (
        <span className="d-flex align-items-end ">
          <small className="text-muted fs-8 fw-500 pe-2">
            Pending Verification
          </small>
          <span id="ach-automated-pending">
            <HelpIcon width={20} height={20} />
          </span>
          <UncontrolledPopover
            placement="top"
            offset={[4, 20]}
            trigger="hover"
            target={"#ach-automated-pending"}
          >
            <PopoverBody
              className="shadow fs-8 fw-500"
              style={{ lineHeight: "18px" }}
            >
              Your account will be automatically verified within 24 to 48 hours.
              Once verified, you will be able to use this payment method.
            </PopoverBody>
          </UncontrolledPopover>
        </span>
      );
    }
    if (data?.status === ACH_FLOW_STATUS.VERIFIED) {
      return (
        <span className="text-right">
          {_renderDeleteButton()}
          {onEdit && (
            <button
              className="btn btn-sm btn-outline-light ms-3"
              disabled={actionType === ACTION_TYPE.PAYMENT_ACH_GET_TOKEN}
              onClick={onEdit}
            >
              <EditIcon width={12} height={12} />
              <span className="ps-1">Edit</span>
            </button>
          )}
        </span>
      );
    }
    if (data?.status === ACH_FLOW_STATUS.FAILED) {
      return (
        <>
          {_renderDeleteButton()}
          <span className="fs-8 text-danger ps-2">
            <CloseIcon width={8} height={8} mr={4} /> Verify failed
          </span>
        </>
      );
    }
  };

  if (!data) {
    return (
      <Card className="h-100">
        <CardHeader className="d-flex justify-content-between align-items-center bg-transparent text-primary fw-semibold">
          ACH
          <span className="text-right">
            <button
              className="btn btn-sm btn-outline-light ms-3"
              disabled={actionType === ACTION_TYPE.PAYMENT_ACH_GET_TOKEN}
              onClick={onCreate}
            >
              <PlusIcon width={12} height={12} />
              <span className="ps-1">ACH</span>
            </button>
          </span>
        </CardHeader>
        <CardBody className="d-flex flex-column justify-content-between p-4 fw-500 text-primary">
          <div>
            <p className="text-muted mb-2">ACH is not currently setup.</p>
            <a
              className="text-primary fs-8 fw-semibold"
              style={{ letterSpacing: "0.1em" }}
              href="https://zealinnovations.freshdesk.com/support/solutions/articles/150000063828-ach-registration-process"
              target="_blank"
              rel="noreferrer"
            >
              LEARN MORE ABOUT OUR ACH PROCESS{" "}
              <ArrowRightIcon width={9} height={16} />
            </a>
          </div>
        </CardBody>
      </Card>
    );
  }

  const { status, bankInfo, cc_name, last_4, created, is_default, id } = data;
  const isVerified = status === ACH_FLOW_STATUS.VERIFIED;
  const achCard = isVerified
    ? {
        bankName: cc_name,
        last_4,
      }
    : {
        bankName: bankInfo?.name || "Banking",
        last_4: bankInfo?.mask,
      };

  return (
    <Card className="h-100">
      <CardHeader
        className="d-flex justify-content-between align-items-center bg-transparent text-primary fw-semibold"
        style={{ minHeight: 74 }}
      >
        ACH
        {_renderAchActions()}
      </CardHeader>
      <CardBody className="d-flex flex-column justify-content-between p-4 fw-500 text-primary">
        <div className="d-flex gap-2 justify-content-between">
          <div className="d-flex gap-2">
            <BankIcon width={15} height={15} />
            <span>
              <p className="fw-semibold mb-2">{achCard.bankName}</p>
              <small className="text-muted fs-8">
                Account # ********
                {achCard.last_4}
              </small>
            </span>
          </div>
          {created && (
            <small className="text-muted fw-500 fs-8">
              Verified{" "}
              {utcToLocal(
                created * 1000,
                DATE_TIME_FORMAT.COMMON_MONTH_DAY_YEAR
              )}
            </small>
          )}
        </div>
        {isVerified && (
          <div className="form-check mt-5">
            <Input
              type="checkbox"
              id={`card-default-${name}`}
              name={`card-default-${name}`}
              defaultChecked={is_default}
              checked={defaultPaymentId === id}
              disabled={
                actionType === ACTION_TYPE.DEFAULT_PAYMENT_METHOD_UPDATE
              }
              onChange={() => setDefaultPaymentMethod(id)}
            />
            <label className="" htmlFor={`card-default-${name}`}>
              Default payment method
            </label>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

PayACHCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
};
PayACHCard.defaultProps = {};

export default PayACHCard;
