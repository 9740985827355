/* eslint-disable react/prop-types */
import React from "react";

export const ChevronUpIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
    transform: props.transform,
  };
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6L1.0575 7.0575L5.25 2.8725V12H6.75V2.8725L10.935 7.065L12 6L6 0L0 6Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
