import React, { useEffect, useState } from "react";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  ChevronLeftIcon,
  ChevronUpIcon,
  DoubleChevronLeft,
  SearchIcon,
} from "components/svg";
import { useSettings } from "context/Settings";
import PropTypes from "prop-types";

import "./index.scss";

// eslint-disable-next-line react/prop-types
const DefaultColumnFilter = ({ column }) => {
  // eslint-disable-next-line react/prop-types
  const { filterValue, setFilter } = column;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${column?.Header}`} // eslint-disable-line
    />
  );
};

// eslint-disable-next-line react/prop-types
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(
    (value) => setGlobalFilter(value || undefined),
    200
  );

  return (
    <div className="d-flex gap-2 align-items-end text-muted">
      <SearchIcon width={18} height={18} />
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
        className="form-control border-top-0 border-start-0 border-end-0 fs-7 fw-500"
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const options = [
  { value: 10, label: "10" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const MainTable = (props) => {
  const { columns, data, name, isLoading, onCellClick } = props;
  const { setSettings, settings } = useSettings();

  const filterTypes = React.useMemo(
    () => ({
      // fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    setPageSize,
    previousPage,
    state: { pageIndex, globalFilter, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0, pageSize: settings.video_list_page_size },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const _handlePageSize = (e) => {
    const value = +e.target.value; // value must be number;
    setSettings({ ...settings, video_list_page_size: value });
    setPageSize(value);
  };

  useEffect(() => {
    if (settings) {
      setPageSize(settings.video_list_page_size);
      gotoPage(settings.last_page_visited);
    }
  }, []); //eslint-disable-line

  if (!columns || !data) {
    return null;
  }

  return (
    <div className="main-table" {...getTableProps()}>
      <div className="d-flex justify-content-between p-4">
        <div className="d-flex align-items-center gap-2 text-muted fs-7 fw-500">
          <span>Show</span>
          <div className="position-relative">
            <select
              defaultValue={options[0]?.value}
              value={pageSize}
              onChange={_handlePageSize}
              className="form-select rounded"
              style={{
                width: 100,
                padding: 10,
                backgroundPosition: "right 11px center",
              }}
            >
              {options &&
                options.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                ))}
            </select>
            <div
              className="position-absolute bg-light"
              style={{ width: 1, top: 9, bottom: 9, right: 34 }}
            ></div>
          </div>
          <span>{name}</span>
        </div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table className="table table-hover">
        <thead className="main-table__head">
          {headerGroups.map((headerGroup, idx) => (
            <tr
              key={idx}
              className="main-table__head__row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, jdx) => (
                <th
                  scope="col"
                  key={jdx}
                  className="text-muted fs-8 py-4 main-table__head__row__cell"
                >
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span className="ps-2">
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <ChevronUpIcon transform="rotate(180deg)" />
                        ) : (
                          <ChevronUpIcon />
                        ))}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={999} className="text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()} className="main-table__body">
            {page.map((row, idx) => {
              prepareRow(row);
              return (
                <tr
                  key={idx}
                  className="main-table__body__row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    const isClickable = cell.column?.canClick !== false;
                    return (
                      <td
                        key={cellIndex}
                        className={`fs-7 fw-500 text-primary main-table__body__row__cell ${
                          isClickable ? "clickable" : ""
                        }`}
                        style={cell.column.style}
                        {...cell.getCellProps()}
                        onClick={() =>
                          isClickable && onCellClick
                            ? onCellClick(cell)
                            : undefined
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div className="d-flex justify-content-between align-items-end p-4 pe-3">
        <span className="text-muted fs-7 fw-500">{`Showing ${
          pageSize * pageIndex + 1
        } to ${pageSize * pageIndex + page.length} of ${
          rows.length
        } ${name}`}</span>
        <div className="d-flex flex-nowrap align-items-center">
          <button
            type="button"
            className="btn btn-link text-muted p-1"
            onClick={() => {
              gotoPage(0);
              setSettings({ ...settings, last_page_visited: 0 });
            }}
            disabled={!canPreviousPage}
          >
            <DoubleChevronLeft width={15} height={15} />
          </button>
          <button
            type="button"
            className="btn btn-link text-muted p-1"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <ChevronLeftIcon width={15} height={15} />
          </button>
          <div className="styles.page_buttons">
            {pageOptions.map((page, idx) => {
              let prevNum = pageIndex - 1;
              let currentNum = pageIndex;
              let nextNum = pageIndex + 1;

              if (prevNum < 0) {
                prevNum = 0;
                currentNum = 1;
                nextNum = 2;
              }

              if (nextNum === pageOptions.length) {
                prevNum = pageOptions.length - 3;
                currentNum = pageOptions.length - 2;
                nextNum = pageOptions.length - 1;
              }

              if (idx === prevNum || idx === currentNum || idx === nextNum) {
                return idx !== pageOptions.length ? (
                  <button
                    type="button"
                    key={idx}
                    className={`btn text-primary fs-7 fw-500 ${
                      idx === pageIndex ? "btn-outline-secondary" : "btn-link"
                    }`}
                    style={{ padding: "4px 5px", borderRadius: 3 }}
                    onClick={() => {
                      setSettings({ ...settings, last_page_visited: idx });
                      gotoPage(idx);
                    }}
                  >
                    {page + 1}
                  </button>
                ) : (
                  ""
                );
              }
              return null;
            })}
          </div>
          <button
            type="button"
            className="btn btn-link text-muted p-1"
            onClick={() => {
              setSettings({
                ...settings,
                last_page_visited: settings.last_page_visited + 1,
              });
              nextPage();
            }}
            disabled={!canNextPage}
          >
            <ChevronLeftIcon
              transform="rotate(180deg)"
              width={15}
              height={15}
            />
          </button>
          <button
            type="button"
            className="btn btn-link text-muted p-1"
            onClick={() => {
              setSettings({ ...settings, last_page_visited: pageCount - 1 });
              gotoPage(pageCount - 1);
            }}
            disabled={!canNextPage}
          >
            <DoubleChevronLeft
              transform="rotate(180deg)"
              width={15}
              height={15}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
MainTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onCellClick: PropTypes.func,
};
MainTable.defaultProps = {
  data: [],
  isLoading: false,
  onCellClick: undefined,
};
export default MainTable;
