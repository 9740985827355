import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

const SettingsContext = React.createContext();

const useSettings = () => {
  const c = useContext(SettingsContext);
  if (!c) throw new Error("useSettings must be used in SettingsProvider");
  return c;
};

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    video_list_page_size: 50,
    last_page_visited: 0,
  });

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.any,
};
SettingsProvider.defaultProps = {
  children: undefined,
};
export default SettingsContext;
export { useSettings, SettingsProvider };
