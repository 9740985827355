import React from "react";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import SecureInput from "../shared/SecureInput";
import { CloseIcon, SaveIcon } from "../svg";

const ChangePasswordModal = (props) => {
  const { target, isSubmitting, onSubmit } = props;

  return (
    <ActionModal target={target} title="Change Password">
      <form onSubmit={onSubmit}>
        <div className="modal-body d-flex flex-column fw-500 gap-md-5 gap-3">
          <small className="text-muted col" style={{ lineHeight: "18px" }}>
            Password must be at least 8 characters and contain at least one
            uppercase and one special character.
          </small>
          <SecureInput
            label="New Password"
            inputProps={{ id: "newPassword", name: "password", required: true }}
          />
          <SecureInput
            label="Confirm New Password"
            inputProps={{
              id: "confirmPassword",
              name: "confirmPassword",
              required: true,
            }}
          />
        </div>

        <div className="modal-footer">
          <button
            type="reset"
            data-bs-dismiss="modal"
            data-bs-target={`#${target}-modal`}
            aria-label="Close"
            className="btn btn-sm btn-outline-light d-flex align-items-center"
            disabled={isSubmitting}
          >
            <CloseIcon width={12} height={12} />
            <span className="ms-2">Cancel</span>
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <SaveIcon width={12} height={12} />
            )}
            <span className="ms-2">Save</span>
          </button>
        </div>
      </form>
    </ActionModal>
  );
};
ChangePasswordModal.propTypes = {
  target: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
ChangePasswordModal.defaultProps = {
  isSubmitting: false,
};
export default ChangePasswordModal;
