import { EcomLogoIcon, LaunchLogoIcon, StreamLogoIcon } from "components/svg";

export const PLAN_CODE = {
  PERSONAL: "PERSONAL",
  PRO: "PRO",
  ELITE: "ELITE",
  MARKETER_LITE: "MARKETER LITE",
  MARKETER_MASTERY: "MARKETER MASTERY",
};
export const PLAN_DETAIL = {
  [PLAN_CODE.PERSONAL]: {
    overview:
      "For Marketers Who Want to Be Heroes to Their Audience While Building Brands With Compelling Stories that Captivate",
    features1: [
      {
        title: "Storage & Bandwidth",
        content: [
          "FREE forever",
          "Five videos stored",
          "100 GB of Bandwidth per Month",
        ],
        divider: true,
      },
      {
        title: "Features",
        content: [
          "Standard Streaming",
          "Basic Analytics",
          "Customizable Player",
          "Autoplay",
        ],
      },
    ],
  },
  [PLAN_CODE.PRO]: {
    overview:
      "For Growing Online Marketers That Want to Grow Their Businesses By getting More Views and Increasing Conversions",
    features1: [
      {
        title: "Storage & Bandwidth",
        content: [
          "50 Videos stored for free",
          "500 GB of Bandwidth/month",
          "25¢ per Additional Video",
        ],
        divider: true,
      },
      {
        title: "Features",
        content: [
          "Standard Streaming",
          "Basic Analytics",
          "Customizable Player",
          "Autoplay",
        ],
      },
    ],
  },
  [PLAN_CODE.ELITE]: {
    overview:
      "For Elite, Data-Driven Marketers Who Want the Best Quality Videos and Analytics While Saving Time and Money",
    features1: [
      {
        title: "Storage & Bandwidth",
        content: [
          "300 Videos stored for free",
          "1000 GB of Bandwidth/month",
          "25¢ per Additional Video",
        ],
        divider: true,
      },
      {
        title: "Features",
        content: [
          "Standard Streaming",
          "Basic Analytics",
          "Customizable Player",
          "Autoplay",
          "Flex Player",
          "Translation",
          "Transcription",
        ],
      },
    ],
  },
  [PLAN_CODE.MARKETER_LITE]: {
    overview:
      "For Elite, Data-Driven Marketers Who Want the Best Quality Videos and Analytics While Saving Time and Money",
    features1: [
      {
        title: "Storage & Bandwidth",
        content: [
          "500 Videos stored for free",
          "1000 GB of Bandwidth/month",
          "20¢ per Additional Video",
        ],
        divider: true,
      },
      {
        title: "Features",
        content: [
          "Standard Streaming",
          "Basic Analytics",
          "Customizable Player",
          "Autoplay",
          "Flex Player",
          "Translation",
          "Transcription",
          "Stitcher",
          ["Transcription Editor", "*"],
          ["Advanced Analytics", "*"],
          ["Content Delivery Paywall", "*"],
        ],
      },
      { title: "* Coming Soon", content: undefined },
    ],
  },
  [PLAN_CODE.MARKETER_MASTERY]: {
    overview:
      "For Elite, Data-Driven Marketers Who Want the Best Quality Videos and Analytics While Saving Time and Money",
    features1: [
      {
        title: "Storage & Bandwidth",
        content: [
          "600 Videos stored for free",
          "1000 GB of Bandwidth/month",
          "20¢ per Additional Video",
        ],
        divider: true,
      },
      {
        title: "Features",
        content: [
          "Standard Streaming",
          "Basic Analytics",
          "Customizable Player",
          "Autoplay",
          "Flex Player",
          "Translation",
          "Transcription",
          "Stitcher",
          ["Transcription Editor", "*"],
          ["Advanced Analytics", "*"],
          ["Content Delivery Paywall", "*"],
        ],
      },
      { title: "* Coming Soon", content: undefined },
    ],
  },
};

export const PLAN_OPTION = {
  CURRENT: "CURRENT",
  DOWNGRADE: "DOWNGRADE",
  UPGRADE: "UPGRADE",
};

export const PLAN_UPDATE_STATE = {
  TERM_OF_USE: "term-of-use",
  ADD_CREDIT_CARD: "add-credit-card",
  ASK_TO_CONFIRM: "ask-to-confirm",
  EARLY_ADOPTER_PRICING: "early-adopter-pricing",
  SUCCESS: "success",
  FAIL: "fail",
};

export const STRING = {
  DOWNGRADE_TERM:
    "By downgrading today, you will be reducing your storage space and bandwidth. If you are over capacity based on the new plan, you will be charged for overages on your next bill. Also, if you are in the middle of your billing cycle, your account will be prorated for the current cycle.",
  UPGRADE_TERM:
    "By upgrading today, you will be able to add storage space, additional bandwidth, and cost savings on additional bandwidth. If you are in the middle of your billing cycle, your account will be prorated for the current cycle.",
  UPGRADE_TERM_SUBMIT:
    "By clicking submit below you are agreeing to the {0} cost of {1} which will be prorated towards the next billing cycle.",
  UNSUBSCRIBE_ECOM:
    "Zeal Ecom is the headless e-commerce platform you have been waiting for. And while you may have to wait a little longer, we are sure that you will find simplicity, flexibility, and the power you need " +
    "to maximize your e-commerce channel. Add your name to the growing list of those wanting to be involved in our pilot program below.",
  UNSUBSCRIBE_LAUNCH:
    "Are you using complex sales funnels with various opt-in forms and dripping content? We are excited to announce our new funnel control system call Zeal Launch. We are currently gathering a list of those " +
    "interested in our pilot program. Enter your email below to get updates and be a part of the first group to get their hands on this powerful platform.",
};

export const DATE_TIME_FORMAT = {
  MONTH_DAY_ORDINAL_YEAR: "MMMM Do, YYYY",
  MONTH_DAY_ORDINAL: "MMMM Do",
  PAYMENT_CARD_EXP_DATE_LIST: ["MM/YYYY", "M/YYYY", "MM-YYYY", "M-YYYY"],
  COMMON_MONTH_DAY_YEAR: "MM/DD/YYYY",
  COMMON_MONTH_YEAR: "MMMM YYYY",
};

export const SYSTEMS = {
  ze: "ecom",
  zs: "stream",
  zl: "launch",
  zi: "innovations",
  ecom: "ze",
  stream: "zs",
  launch: "zl",
  innovations: "zi",
};

export const INVITE_USER_STATUS = {
  ACTIVE: { name: "ACTIVE", displayName: "Active" },
  INACTIVE: { name: "INACTIVE", displayName: "Inactive" },
  PENDING: { name: "PENDING INVITE", displayName: "Pending Invite" },
};

export const ROLE_OPTIONS = [
  { value: "MEMBER", label: "Member" },
  { value: "ADMIN", label: "Admin" },
  { value: "CONTRIBUTOR", label: "Contributor" },
];

export const SYSTEM_ROLE = {
  ZI_ADMIN: "ADMIN",
  ZI_USER: "USER",
};

export const SYSTEM_LOGO = {
  [SYSTEMS.stream]: <StreamLogoIcon width={111} height={30} />,
  [SYSTEMS.ecom]: <EcomLogoIcon width={111} height={34} />,
  [SYSTEMS.launch]: <LaunchLogoIcon width={111} height={32} />,
};

export const SYSTEM_DISPLAY_NAME = {
  [SYSTEMS.stream]: "Zeal Stream",
  [SYSTEMS.ecom]: "Zeal Ecom",
  [SYSTEMS.launch]: "Zeal Launch",
  [SYSTEMS.innovations]: "Zeal Innovations",
};

export const errorCode = {
  INVITE_EXIST_USER: "2005",
};

export const REGEX_PATTERN = {
  USER_PASS:
    /^(?=.*[A-Z])(?=.*[!@#$%^&*()\\-`.+,/|])[a-zA-Z0-9!@#$%^&*()\\-`.+,/|]{8,128}$/,
};

export const OVERAGE_CREDIT_PAYMENT_METHOD = {
  CARD_ON_FILE: "card_on_file",
  ANOTHER_CARD: "another_card",
  ACH: "ach",
};

export const ACH_FLOW_TYPE = {
  INSTANT_MATCH: "Instant-match",
  AUTOMATED_MICRO_DEPOSITS: "Automated-Micro-deposits",
  MANUAL_MICRO_DEPOSITS: "Manual-Micro-deposits",
};

export const ACH_FLOW_STATUS = {
  PENDING: "pending",
  VERIFIED: "verified",
  FAILED: "failed",
};
