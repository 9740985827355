import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "development") {
      console.log("----- Error Boundary -----", { error, errorInfo }); // eslint-disable-line no-console
      this.setState({
        hasError: true,
        error,
        errorInfo,
      });
    }
    // You can use your own error logging service here
    // logErrorToMyService
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      console.error(error); // eslint-disable-line no-console
      return null;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};
ErrorBoundary.defaultProp = {
  children: undefined,
};
export default ErrorBoundary;
