/* eslint-disable react/prop-types */
import React from "react";

export const MastercardIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="25"
      height="16"
      viewBox="0 0 25 16"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1091_3173)">
        <path
          d="M15.8727 1.65382H9.1073V13.8085H15.8727V1.65382Z"
          fill="#FF5A00"
        />
        <path
          d="M9.55765 7.73118C9.55765 5.26171 10.7186 3.06996 12.5 1.65382C11.189 0.622998 9.53513 0 7.73118 0C3.45777 0 0 3.45777 0 7.73118C0 12.0046 3.45777 15.4624 7.73118 15.4624C9.53513 15.4624 11.189 14.8394 12.5 13.8085C10.7161 12.4124 9.55765 10.2007 9.55765 7.73118Z"
          fill="#EB001B"
        />
        <path
          d="M25 7.73118C25 12.0046 21.5422 15.4624 17.2688 15.4624C15.4649 15.4624 13.811 14.8394 12.5 13.8085C14.3039 12.3899 15.4424 10.2007 15.4424 7.73118C15.4424 5.26171 14.2814 3.06996 12.5 1.65382C13.8085 0.622998 15.4624 0 17.2663 0C21.5422 0 25 3.48028 25 7.73118Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1091_3173">
          <rect width="25" height="15.5124" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
