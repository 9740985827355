import React, { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

function PlaidModal(props) {
  const { achToken, onLoad, onAccepted } = props;
  const { open, ready } = usePlaidLink({
    token: achToken?.link_token,
    onLoad,
    onEvent: (err) => {
      if (err !== null) {
        toast.error(err.display_message);
      }
    },
    onSuccess: (public_token, metadata) => {
      onAccepted(metadata);
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <></>;
}

PlaidModal.propTypes = {
  achToken: PropTypes.shape({
    expiration: PropTypes.string,
    link_token: PropTypes.string,
    request_id: PropTypes.string,
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  onAccepted: PropTypes.func.isRequired,
};

export default PlaidModal;
