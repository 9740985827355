import React from "react";
import { Outlet } from "react-router-dom";

import BaseLayout from "../../layouts/BaseLayout";

const RedirectIfAuthRoute = () => {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

export default RedirectIfAuthRoute;
