import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import {
  AddOveragesCreditsModal,
  BillingEmailCard,
  OverageCredits,
  PayACHCard,
  PayCreditCard,
  PlaidModal,
} from "components/Billing";
import InvoiceHistoryTable from "components/InvoiceHistoryTable";
import { RefreshIcon } from "components/svg";
import UpdatePaymentMethodModal from "components/UpdatePaymentMethodModal";
import {
  ACH_FLOW_STATUS,
  ACH_FLOW_TYPE,
  DATE_TIME_FORMAT,
  PLAN_OPTION,
  SYSTEM_ROLE,
  SYSTEMS,
} from "constant";
import BillingContext, { ACTION_TYPE } from "context/Billing";
import { useUser } from "context/User";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { closeModal } from "helpers/functions";
import restServiceHelper from "helpers/restServiceHelper";

import "./index.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const FRESHDESK_GROUP_ID = {
  BILLING: 150000051294,
};
const FRESHDESK_PRODUCT_ID = {
  ZEAL_STREAM: 150000000214,
};

const Billing = () => {
  const { user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionType, setActionType] = useState();
  const [currentCycle, setCurrentCycle] = useState();
  const [preCycle, setPreCycle] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [invoiceHistories, setInvoiceHistories] = useState();
  const [currentOverage, setCurrentOverage] = useState(0);
  const [overageCredits, setOverageCredits] = useState();
  const [achToken, setACHToken] = useState({});
  const [isRequestMoreCredit, setIsRequestMoreCredit] = useState(false);
  const [defaultPaymentId, setDefaultPaymentId] = useState();
  const stripeOptions = {};
  let achStatusTimeout;
  const isAdmin = user?.role === SYSTEM_ROLE.ZI_ADMIN;

  const _getUsage = async (cycle) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/usage?account_id=${user?.account_id}&cycle=${cycle}`
      );
      const { success, record } = res.data;
      if (success) {
        if (cycle) {
          setPreCycle(record);
        } else {
          setCurrentCycle(record);
        }
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      if (cycle) {
        setPreCycle({});
      } else {
        setCurrentCycle({});
      }
    }
  };

  const _getProducts = async (systemId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/products/get?system_id=${systemId}&account_id=${user?.account_id}`
      );
      const { success, products } = res.data;
      if (success) {
        const currPlan = products
          ? products.find((e) => e.option === PLAN_OPTION.CURRENT)
          : {};
        setCurrentPlan(currPlan);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setCurrentPlan({});
    }
  };

  const _getPaymentMenthod = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/payment_info?account_id=${user?.account_id}`
      );
      const { success, record } = res.data;
      if (success) {
        const initPaymentMethod = { billing_email: record?.billing_email };
        record.paymentMethods.forEach((method) => {
          initPaymentMethod[method.type] = { ...method };
          if (method?.is_default) {
            setDefaultPaymentId(method.id);
          }
        });
        setPaymentMethod((preState) => ({
          ...preState,
          ...initPaymentMethod,
          ach: initPaymentMethod?.ach
            ? {
                ...preState?.ach,
                ...initPaymentMethod.ach,
                status: ACH_FLOW_STATUS.VERIFIED,
              }
            : preState?.ach,
        }));
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setPaymentMethod({
        card: undefined,
        ach: undefined,
        billing_email: undefined,
      });
    }
  };

  const _getInvoiceHistories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/history?account_id=${user?.account_id}`
      );
      const { success, record } = res.data;
      if (success) {
        setInvoiceHistories(record);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setInvoiceHistories([]);
    }
  };

  /** Overage Credit area **/
  const _getCurrentOverage = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/current_overage?account_id=${user?.account_id}`
      );
      const { success, record } = res.data;
      if (success) {
        setCurrentOverage(record?.overage_credits || 0);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    }
  };

  const _getOverageCredits = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/overage_credits?plan_type=${user?.overage_model}&cc_condition=true`
      );
      const { success, record } = res.data;
      if (success) {
        setOverageCredits(record);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setOverageCredits([]);
    }
  };

  const _handlePlaidModalLoaded = () => {
    document.getElementById(`${"add-overages-credits"}-close`)?.click();
  };

  const _payOverageCredit = async (payMethod, data, paid) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/overage_credits`,
        {
          account_id: user?.account_id,
          plan_id: data?.plan_id,
          payment_method: payMethod,
          paid,
        }
      );
      const { success, result } = res?.data;
      if (success && !result) {
        Promise.allSettled([_getCurrentOverage(), _getInvoiceHistories()]);
      }
      return res?.data;
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    }
  };

  const _requestMoreOverageCredits = async () => {
    try {
      setIsRequestMoreCredit(undefined);
      const res = await axios.post(
        `${process.env.REACT_APP_FRESHDESK_API_URL}/api/v2/tickets`,
        {
          product_id: FRESHDESK_PRODUCT_ID.ZEAL_STREAM,
          group_id: FRESHDESK_GROUP_ID.BILLING,
          name: `${user?.first_name} ${user?.last_name}`,
          email: user?.email,
          tags: [process.env.NODE_ENV],
          type: "Feature Request",
          description: "Discuss bulk pricing and purchasing.",
          subject: "Support Add Overages Credits",
          source: 1,
          priority: 1,
          status: 2,
        },
        {
          auth: {
            username: process.env.REACT_APP_FRESHDESK_API_KEY,
            password: process.env.REACT_APP_FRESHDESK_API_PASSWORD,
          },
        }
      );
      if (res?.status === 201) {
        setIsRequestMoreCredit(true);
        toast.success("Large Credit request submitted");
      }
    } catch (err) {
      setIsRequestMoreCredit(false);
      toast.error(restServiceHelper.handleError(err));
    }
  };

  /** Overage Credit area ended **/

  useEffect(() => {
    let fetchDataPromises = [
      _getUsage(),
      _getUsage("PREVIOUS"),
      _getCurrentOverage(),
      _getOverageCredits(),
    ];
    if (isAdmin) {
      fetchDataPromises = fetchDataPromises.concat([
        _getProducts(SYSTEMS.stream),
        _getPaymentMenthod(),
        _checkACHPaymentStatus(),
        _getInvoiceHistories(),
      ]);
    }
    Promise.allSettled(fetchDataPromises);
    return () => {
      clearTimeout(achStatusTimeout);
    };
  }, []); // eslint-disable-line

  /** Payment Methods area **/
  const _setupACH = async (data) => {
    try {
      if (paymentMethod?.ach?.type === ACH_FLOW_TYPE.MANUAL_MICRO_DEPOSITS) {
        await _verifyManualACHPayment(data);
        return;
      }
      setACHToken(undefined);
      setActionType(ACTION_TYPE.PAYMENT_ACH_GET_TOKEN);
      const ACH_VERIFY_STATUS_TYPE = {
        null: "INSTANT_ACH",
        pending_automatic_verification: "AUTOMATED_ACH",
        pending_manual_verification: "MANUAL_ACH",
      };
      const ACH_STATUS_MESSAGE = {
        null: "Your ACH account has been added.",
        pending_automatic_verification:
          "Your ACH account has been added but is pending Automated Micro-Deposit Verification.",
        pending_manual_verification:
          "Your ACH account has been added but is pending Manual Micro-Deposit Verification.",
      };

      const {
        public_token,
        account_id: bank_account_id,
        account,
        institution,
      } = data || {};

      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/ach_token`,
        {
          account_id: user?.account_id,
          type: public_token
            ? ACH_VERIFY_STATUS_TYPE[account?.verification_status]
            : "LINK_TOKEN",
          public_token,
          bank_account_id,
          bank_info: JSON.stringify({ ...institution, mask: account?.mask }),
        }
      );
      const { success, record } = res?.data;
      if (success && record) {
        setACHToken(record);
        return;
      }
      if (success) {
        toast.success(ACH_STATUS_MESSAGE[account?.verification_status], {
          autoClose: 10000,
        });
        setACHToken({});
        _checkACHPaymentStatus();
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setACHToken({});
    } finally {
      setActionType(undefined);
    }
  };

  const _checkACHPaymentStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/ach_status?account_id=${user?.account_id}`
      );
      const { success, record } = res?.data;
      if (
        success &&
        typeof record === "object" &&
        JSON.stringify(record) !== "{}"
      ) {
        const achPayment = {};
        if (record?.instant_status) {
          achPayment.type = ACH_FLOW_TYPE.INSTANT_MATCH;
          achPayment.status = record.instant_status;
          await _getPaymentMenthod();
        }
        if (record?.automated_deposit_status) {
          achPayment.type = ACH_FLOW_TYPE.AUTOMATED_MICRO_DEPOSITS;
          achPayment.status = record.automated_deposit_status;
          achPayment.bankInfo = record.deposit_bank_info
            ? JSON.parse(record.deposit_bank_info)
            : {};
        }
        if (record?.manual_deposit_status) {
          achPayment.type = ACH_FLOW_TYPE.MANUAL_MICRO_DEPOSITS;
          achPayment.status = record.manual_deposit_status;
          achPayment.bankInfo = record.deposit_bank_info
            ? JSON.parse(record.deposit_bank_info)
            : {};
        }

        setPaymentMethod((preState) => ({
          ...preState,
          ach: { ...preState?.ach, ...achPayment },
        }));
      }
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
    }
  };

  const _verifyManualACHPayment = async (data) => {
    try {
      setACHToken(undefined);
      setActionType(ACTION_TYPE.PAYMENT_ACH_MANUAL_VERIFY);
      const { public_token } = data;
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/verify_manual_ach`,
        {
          account_id: user?.account_id,
          public_token,
          type: public_token ? "VERIFY_SUCCEEDED" : "VERIFY",
        }
      );
      const { success, result } = res?.data;
      if (success && result) {
        setACHToken(result);
        return;
      }
      if (success) {
        _getPaymentMenthod();
        toast.success("Your ACH account has been verified!");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setACHToken({});
    } finally {
      setActionType(undefined);
    }
  };

  const _deleteACHPayment = async () => {
    try {
      setActionType(ACTION_TYPE.PAYMENT_ACH_DELETE);
      const res = await axios.delete(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/payment_info`,
        { data: { account_id: user?.account_id, type: "ach" } }
      );
      const { success } = res?.data;
      if (success) {
        _getPaymentMenthod();
        setPaymentMethod((preState) => ({ ...preState, ach: undefined }));
        toast.success("ACH account removed.");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setActionType(undefined);
    }
  };

  const _updatePaymentMethod = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      setActionType(ACTION_TYPE.PAYMENT_CARD_UPDATE);
      const data = { account_id: user?.account_id, type: "card" };
      const formData = new FormData(event.target);
      for (const [name, value] of formData) {
        data[name] = value;
      }
      const expDate = dayjs(
        data.expired_date,
        DATE_TIME_FORMAT.PAYMENT_CARD_EXP_DATE_LIST
      );
      data.exp_month = expDate.month() + 1;
      data.exp_year = expDate.year();
      data.cc = data.cc.replace(/\D/g, ""); // remove all charactors which is not number

      const res = await axios.put(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/payment_info`,
        data
      );
      if (res?.data?.success) {
        closeModal("update-payment-method-modal");
        await _getPaymentMenthod();
        event.target.reset();
        toast.success("Payment method was updated!");
      }
    } catch (err) {
      const errMessage =
        err?.response?.data?.message?.raw?.message ||
        restServiceHelper.handleError(err);
      toast.error(errMessage);
    } finally {
      setIsSubmitting(false);
      setActionType(undefined);
    }
  };

  const _setDefaultPaymentMethod = async (id) => {
    const oldId = defaultPaymentId;
    try {
      if (id === defaultPaymentId) {
        return;
      }
      setActionType(ACTION_TYPE.DEFAULT_PAYMENT_METHOD_UPDATE);
      setDefaultPaymentId(id);
      const res = await axios.put(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/payment_info`,
        {
          account_id: user?.account_id,
          type: "default_payment",
          payment_method_id: id,
        }
      );
      if (res?.data?.success) {
        toast.success("Default payment method changed.");
        _getPaymentMenthod();
      }
    } catch (err) {
      setDefaultPaymentId(oldId);
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setActionType(undefined);
    }
  };

  const _updateBillingEmail = async (email) => {
    try {
      setActionType(ACTION_TYPE.BILLING_EMAIL_UPDATE);
      const res = await axios.put(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/billing/payment_info`,
        { account_id: user?.account_id, type: "email", new_email: email }
      );
      const { success } = res?.data;
      if (success) {
        toast.success("Billing email changed.");
        setPaymentMethod((preState) => ({ ...preState, billing_email: email }));
      }
    } catch (err) {
      const message =
        err?.response?.data?.message?.raw?.message ||
        restServiceHelper.handleError(err);
      toast.error(message);
    } finally {
      setActionType(undefined);
    }
  };

  const _renderPaymentMethods = () => {
    if (!paymentMethod) {
      return _renderLoading();
    }
    const { card, ach, billing_email } = paymentMethod;
    const result = [
      <div key="pay-credit-card-111" className="col-12 col-md-4">
        <PayCreditCard data={card} name={1} />
      </div>,
      <div key="pay-ach-111" className="col-12 col-md-4">
        <PayACHCard
          data={ach}
          name={2}
          onCreate={_setupACH}
          onEdit={_setupACH}
          onVerify={_verifyManualACHPayment}
          onDelete={_deleteACHPayment}
        />
      </div>,
      <div className="col-12 col-md-4" key="billing-email">
        <BillingEmailCard email={billing_email} onEdit={_updateBillingEmail} />
      </div>,
    ];
    return result;
  };

  /** Payment Methods area ended **/

  const _renderLoading = () => (
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  return (
    <BillingContext.Provider
      value={useMemo(() => ({
        actionType,
        defaultPaymentId,
        overageCredits,
        isRequestMoreCredit,
        paymentMethod,
        setDefaultPaymentMethod: _setDefaultPaymentMethod,
        payOverageCredit: _payOverageCredit,
        getPlaidLinkToken: _setupACH,
        requestMoreOverageCredits: _requestMoreOverageCredits,
      }))}
    >
      <div className="p-3 p-md-4 p-lg-5 billing-wrapper">
        <div className="card shadow-sm">
          <div className="card-header d-flex justify-content-between align-items-center bg-transparent text-primary fw-semibold fs-6-lg">
            <span className="d-inline-block my-1">Stream Usage</span>
            <Elements stripe={stripePromise} options={stripeOptions}>
              <OverageCredits
                target="add-overages-credits"
                currentOverage={currentOverage}
              />
              <AddOveragesCreditsModal target="add-overages-credits" />
            </Elements>
          </div>
          <div className="card-body py-4 fw-500 text-center">
            <div className="row g-4">
              <div className="col-12 col-md-4">
                {currentCycle ? (
                  <div className="card h-100 text-start">
                    <div className="card-header bg-transparent text-primary fw-semibold">
                      <p className="my-2">Current Cycle</p>
                    </div>
                    <div className="card-body p-4 d-flex flex-wrap gap-4 justify-content-between">
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8">Bandwidth</p>
                        <span className="d-inline">
                          {currentCycle?.bw_gb_used || "-"}
                        </span>
                        <small className="fs-8">GB</small>
                        <p className="mt-1 mb-0 text-muted fs-8 fst-italic">
                          of {currentCycle?.bw_gb_limit || "-"}
                        </p>
                      </div>
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8">Videos</p>
                        <span className="d-inline">
                          {currentCycle?.videos_used || "-"}
                        </span>
                        <p className="mt-1 mb-0 text-muted fs-8 fst-italic">
                          of {currentCycle?.videos_limit || "-"}
                        </p>
                      </div>
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8">Est. Cost</p>
                        <span className="d-inline">
                          $
                          {currentCycle
                            ? (currentCycle?.plan_cost - 0).toFixed(2)
                            : "-"}
                        </span>
                        <p className="mt-1 mb-0 text-muted fs-8 fst-italic">
                          Due{" "}
                          {currentCycle?.due_date_epoch
                            ? dayjs(currentCycle?.due_date_epoch).format(
                                DATE_TIME_FORMAT.MONTH_DAY_ORDINAL
                              )
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-4">
                {preCycle ? (
                  <div className="card h-100 text-start">
                    <div className="card-header bg-transparent text-primary fw-semibold">
                      <p className="my-2">Previous Cycle</p>
                    </div>
                    <div className="card-body p-4 d-flex flex-wrap gap-4 justify-content-between">
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8">Bandwidth</p>
                        <span className="d-inline">
                          {preCycle?.bw_gb_used || "-"}
                        </span>
                        <small className="fs-8">GB</small>
                        <p className="mt-1 mb-0 text-muted fs-8 fst-italic">
                          of {preCycle?.bw_gb_limit || "-"}
                        </p>
                      </div>
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8">Videos</p>
                        <span className="d-inline">
                          {preCycle?.videos_used || "-"}
                        </span>
                        <p className="mt-1 mb-0 text-muted fs-8 fst-italic">
                          of {preCycle?.videos_limit || "-"}
                        </p>
                      </div>
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8">Total</p>
                        <span className="d-inline">
                          $
                          {preCycle
                            ? (
                                preCycle?.plan_cost - preCycle?.overage_cost
                              ).toFixed(2)
                            : "-"}
                        </span>
                        <p className="mt-1 mb-0 text-muted fs-8 fst-italic">
                          Paid{" "}
                          {preCycle?.due_date_epoch
                            ? dayjs(preCycle?.due_date_epoch).format(
                                DATE_TIME_FORMAT.MONTH_DAY_ORDINAL
                              )
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  _renderLoading()
                )}
              </div>
              <div className="col-12 col-md-4">
                {isAdmin && currentPlan ? (
                  <div className="card h-100 text-start">
                    <div className="card-header d-flex justify-content-between align-items-center bg-transparent text-primary fw-semibold">
                      Current Plan
                      <NavLink to="/" className="btn btn-sm btn-outline-light">
                        <RefreshIcon width={12} height={16} />
                        <span className="ps-1">Change</span>
                      </NavLink>
                    </div>
                    <div className="card-body p-4 d-flex flex-wrap gap-4 justify-content-between">
                      <div className="card_data">
                        <p className="mb-2 pb-1 text-muted fs-8 text-capitalize">
                          {currentPlan?.plan?.toLowerCase()}
                        </p>
                        <span className="d-inline">
                          ${currentPlan?.price?.toFixed(2)}
                        </span>
                        <small className="text-muted">
                          /{currentPlan?.frequency?.slice(0, -2)}
                        </small>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isAdmin ? (
          <>
            <div className="card shadow-sm mt-5">
              <div className="card-header bg-transparent text-primary fw-semibold fs-6-lg">
                <div className="d-flex justify-content-between align-items-center">
                  Payment Method
                  {/* <button
                    className="btn btn-sm btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target={"#update-payment-method-modal"}
                  >
                    <EditIcon width={12} height={12} />
                    <span className="ps-1">Edit</span>
                  </button> */}
                </div>
              </div>
              <div className="card-body p-4 fw-500">
                <div className="row g-4 justify-content-center">
                  {_renderPaymentMethods()}
                </div>
                {paymentMethod?.card ? (
                  <div>
                    <UpdatePaymentMethodModal
                      target="update-payment-method-modal"
                      initialData={paymentMethod.card}
                      isSubmitting={isSubmitting}
                      onSubmit={_updatePaymentMethod}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="card shadow-sm mt-5">
              <div className="card-header bg-transparent text-primary fw-semibold fs-6-lg">
                <span className="d-inline-block my-1">History</span>
              </div>
              <div className="px-0">
                <InvoiceHistoryTable
                  data={invoiceHistories || []}
                  isLoading={invoiceHistories === undefined}
                />
              </div>
            </div>
          </>
        ) : null}
        {achToken ? (
          <PlaidModal
            achToken={achToken}
            onLoad={_handlePlaidModalLoaded}
            onAccepted={_setupACH}
          />
        ) : null}
      </div>
    </BillingContext.Provider>
  );
};

export default Billing;
