/* eslint-disable react/prop-types */
import React from "react";

export const RefreshIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0L3.33333 2.66667L6 5.33333V3.33333C8.57267 3.33333 10.6667 5.42667 10.6667 8C10.6667 8.58667 10.5528 9.14673 10.3542 9.66406L11.3646 10.6732C11.7679 9.86718 12 8.96133 12 8C12 4.692 9.30867 2 6 2V0ZM0.635417 5.32682C0.232083 6.13282 0 7.03867 0 8C0 11.308 2.69133 14 6 14V16L8.66667 13.3333L6 10.6667V12.6667C3.42733 12.6667 1.33333 10.5733 1.33333 8C1.33333 7.41333 1.44717 6.85327 1.64583 6.33594L0.635417 5.32682Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
