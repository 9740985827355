import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { PlanCard } from "components/Access";
import SubcribeProfileForm from "components/SubcribeProfileForm";
import { ChevronUpIcon } from "components/svg";
import UnsubscribeProfileModal from "components/UnsubscribeProfileModal";
import UpdatePlanModal from "components/UpdatePlanModal";
import {
  PLAN_CODE,
  PLAN_OPTION,
  PLAN_UPDATE_STATE,
  STRING,
  SYSTEMS,
} from "constant";
import { useUser } from "context/User";
import restServiceHelper from "helpers/restServiceHelper";

import "./index.scss";

const Access = () => {
  const navigator = useNavigate();
  const { user } = useUser();
  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [modalType, setModalType] = useState();
  const [subscribes, setSubscribes] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const _setCurrentProductOnTop = (originalProducts) => {
    const _products = [...originalProducts];
    const currentProductIndex = _products.findIndex(
      (item) => item.option === PLAN_OPTION.CURRENT
    );
    if (currentProductIndex > -1) {
      _products.unshift({
        ..._products[currentProductIndex],
      });
      _products.splice(currentProductIndex + 1, 1);
      return _products;
    }
    return null;
  };

  const _getProducts = async (systemId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/products/get?system_id=${systemId}&account_id=${user?.account_id}`
      );
      if (res?.data?.success) {
        const originalProducts = res?.data?.products || [];
        const marketerProducts = [];
        const normalProducts = [];
        let sortedProducts;
        originalProducts.forEach((e) => {
          [PLAN_CODE.MARKETER_LITE, PLAN_CODE.MARKETER_MASTERY].includes(
            e?.plan
          )
            ? marketerProducts.push(e)
            : normalProducts.push(e);
        });
        sortedProducts = _setCurrentProductOnTop(marketerProducts);
        if (sortedProducts) {
          setProducts(sortedProducts.concat(normalProducts));
          return;
        }
        sortedProducts = _setCurrentProductOnTop(normalProducts);
        setProducts(sortedProducts.concat(marketerProducts));
      }
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
      setProducts([]);
    }
  };

  const _updateProductPlan = async () => {
    try {
      setIsSubmitting(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/update_plan`,
        {
          account_id: user?.account_id,
          plan_id: selectedProduct?.uuid,
        }
      );
      if (res?.data?.success) {
        toast.success(
          `Plan ${selectedProduct?.option?.toLowerCase()} to ${
            selectedProduct?.plan
          }`
        );
        await _getProducts("zs");
        setModalType(PLAN_UPDATE_STATE.SUCCESS);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _submitCardPayment = async (paymentMethodId) => {
    try {
      setIsSubmitting(true);
      setModalType();
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/update-to-paid`,
        {
          account_id: user?.account_id,
          product_uuid: selectedProduct?.uuid,
          payment_method_id: paymentMethodId,
        }
      );
      if (res?.data?.success) {
        toast.success("Successfully upgraded.");
        await _getProducts("zs");
        setModalType(PLAN_UPDATE_STATE.SUCCESS);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _showTermOfUse = (product, event) => {
    event.preventDefault();
    setSelectedProduct(product);
    setModalType(PLAN_UPDATE_STATE.TERM_OF_USE);
  };

  const _ssoLogin = async () => {
    try {
      setIsSubmitting(true);
      const res = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/2/sso_login`,
        {
          token: user?.jwt,
          to_system: SYSTEMS.stream,
        }
      );

      if (res?.data?.success) {
        const payload = {
          jwt: res.data.jwt,
          accounts: res.data.accounts,
          ...res.data.record,
        };

        window.open(
          `${
            process.env.REACT_APP_REDIRECT_URL_STREAM
          }/videos/?token=${encodeURIComponent(
            window.btoa(JSON.stringify(payload))
          )}`,
          "_blank"
        );
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  /** Subscribe region start */
  const _getSubscribes = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/profile/get_subscribe_news`,
        { email: user?.email }
      );

      if (res?.data?.success) {
        setSubscribes(res.data?.record);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    }
  };

  const _subscribeProfiles = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      let system, subscribed_email;
      const formData = new FormData(event.target);
      for (const [name, value] of formData) {
        system = name;
        subscribed_email = value;
      }
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/profile/subscribe_news`,
        { owner_email: user.email, system, subscribed_email }
      );

      if (res?.data?.success) {
        setSubscribes((state) => ({
          ...state,
          [system]: true,
          [`${system}_email`]: subscribed_email,
        }));
        toast.success("Successfully subscribed");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _unsubscribeProfiles = async (event, system) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      let email;
      const formData = new FormData(event.target);
      for (const data of formData) {
        email = data[1];
      }
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/profile/unsubscribe_news`,
        { system, email }
      );
      if (res?.data?.success) {
        setSubscribes((state) => ({ ...state, [system]: false }));
        toast.success("Successfully unsubscribed");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };
  /** Subscribe region end */

  const _renderProfileSubscriptions = (system) => {
    if (subscribes && subscribes[system]) {
      return (
        <UnsubscribeProfileModal
          target={`${system}-unsubcribe-modal`}
          isSubmitting={isSubmitting}
          defaultEmail={user?.email}
          onConfirmed={(e) => _unsubscribeProfiles(e, system)}
        />
      );
    }
    return (
      <SubcribeProfileForm
        system={system}
        isSubmitting={isSubmitting}
        onSubmit={_subscribeProfiles}
      />
    );
  };

  const _renderSubscribedText = (system) => {
    let text = <p></p>;

    if (system === "ze") {
      subscribes[system]
        ? (text = _getSubscribedText(system))
        : (text = <p className="lh-lg normal_p">{STRING.UNSUBSCRIBE_ECOM}</p>);
    }

    if (system === "zl") {
      subscribes[system]
        ? (text = _getSubscribedText(system))
        : (text = <p className="lh-lg normal_p">{STRING.UNSUBSCRIBE_LAUNCH}</p>);
    }

    return text;
  };

  const _getSubscribedText = (system) => {
    let system_text = "";
    let subscribed_email = "";

    if (system === "ze") {
      system_text = "Zeal Ecom";
      subscribed_email = subscribes.ze_email;
    }
    if (system === "zl") {
      system_text = "Zeal Launch";
      subscribed_email = subscribes.zl_email;
    }

    return (
      <p className="lh-lg mb-0">
        Your email address{" "}
        <span className="fw-semibold">{subscribed_email}</span> is currently on
        our email list for {system_text} updates. If you think this was done by
        mistake or you wish to stop receiving updates and discounts designed
        just for early adopters, you can unsubscribe below.
      </p>
    );
  };

  useEffect(() => {
    navigator(window.location.pathname, {
      replace: true,
    });
    _getProducts("zs");
    _getSubscribes();
  }, []); // eslint-disable-line

  const isAllowSubmitCardPayment =
    products && products[0]?.plan === PLAN_CODE.PERSONAL;

  return (
    <div className="p-3 p-md-4 p-lg-5 access-wrapper">
      <div className="card shadow-sm">
        <div className="card-header d-flex align-items-center justify-content-between bg-transparent">
          <h6 className="fs-6-lg fw-semibold mb-0">Zeal Stream</h6>
          <button
            className="btn btn-sm btn-secondary"
            disabled={isSubmitting}
            onClick={_ssoLogin}
          >
            <span className="me-2">Login</span>
            <ChevronUpIcon transform="rotate(90deg)" />
          </button>
        </div>
        <div className="card-body d-flex flex-wrap gap-3 gap-xl-4 text-start fw-500 text-primary">
          {products === undefined ? (
            <div className="spinner-border text-primary m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : products ? (
            products.map((product, index) => {
              return (
                <PlanCard
                  key={index}
                  index={index}
                  product={product}
                  onShowTermOfUse={_showTermOfUse}
                />
              );
            })
          ) : null}
        </div>
      </div>

      <div className="card shadow-sm my-2 my-sm-5">
        <div className="card-header d-flex align-items-center justify-content-between bg-transparent">
          <h6 className="fs-6-lg fw-semibold mb-0">Zeal Ecom</h6>
        </div>
        <div className="card-body d-flex flex-wrap gap-3 gap-xl-4 text-start fw-500 text-primary py-5">
          <div className="fs-5-lg fw-semibold mb-0">
            {subscribes[SYSTEMS.ecom] ? "You are subscribed" : "Coming Soon"}
          </div>
          {_renderSubscribedText(SYSTEMS.ecom)}
          <div
            className="col-12 col-md-8 col-lg-6"
            style={{ maxWidth: 565, fontSize: 15 }}
          >
            {_renderProfileSubscriptions(SYSTEMS.ecom)}
          </div>
        </div>
      </div>

      <div className="card shadow-sm">
        <div className="card-header d-flex align-items-center justify-content-between bg-transparent">
          <h6 className="fs-6-lg fw-semibold mb-0">Zeal Launch</h6>
        </div>
        <div className="card-body d-flex flex-wrap gap-3 gap-xl-4 text-start fw-500 text-primary py-5">
          <h6 className="fs-5-lg fw-semibold mb-0">
            {subscribes[SYSTEMS.launch] ? "You are subscribed" : "Coming Soon"}
          </h6>
          {_renderSubscribedText(SYSTEMS.launch)}
          <div className="col-12 col-md-8 col-lg-6" style={{ maxWidth: 565 }}>
            {_renderProfileSubscriptions(SYSTEMS.launch)}
          </div>
        </div>
      </div>
      <UpdatePlanModal
        type={modalType}
        onConfirmed={_updateProductPlan}
        onSubmitCard={isAllowSubmitCardPayment ? _submitCardPayment : undefined}
        product={selectedProduct}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};
export default Access;
