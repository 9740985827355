import React from "react";
import { SYSTEM_DISPLAY_NAME } from "constant";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import { CheckIcon, CloseIcon } from "../svg";

const CancelInviteUserModal = (props) => {
  const { target, isSubmitting, email, platform, onConfirmed } = props;

  const isValidData = typeof platform === "object" && email;

  return (
    <ActionModal maxWidth={600} target={target} title="Cancel Invite">
      {isValidData ? (
        <form onSubmit={onConfirmed}>
          <div
            className="modal-body text-start text-primary fw-500"
            style={{ lineHeight: "19px" }}
          >
            {
              "By clicking 'Yes' below, you are confirming that you want to cancel the "
            }
            <b className="text-capitalize">{platform?.role?.toLowerCase()}</b>{" "}
            invite for <b>{SYSTEM_DISPLAY_NAME[platform?.name]}</b> sent to{" "}
            <b>{email}.</b>
          </div>
          <div className="modal-footer">
            <button
              type="reset"
              data-bs-dismiss="modal"
              data-bs-target={`#${target}`}
              aria-label="Close"
              className="btn btn-sm btn-outline-light d-flex align-items-center"
              disabled={isSubmitting}
            >
              <CloseIcon width={12} height={12} />
              <span className="ms-2">Cancel</span>
            </button>
            <button
              type="submit"
              data-bs-target={`#${target}`}
              className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <CheckIcon width={12} height={12} />
              )}
              <span className="ms-2">Yes</span>
            </button>
          </div>
        </form>
      ) : null}
    </ActionModal>
  );
};

CancelInviteUserModal.propTypes = {
  target: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  platform: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
  }),
  onConfirmed: PropTypes.func.isRequired,
};
CancelInviteUserModal.defaultProps = {
  platform: undefined,
};
export default CancelInviteUserModal;
