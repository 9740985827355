import React from "react";
import { toast } from "react-toastify";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";

const stripeInputStyles = {
  color: "#421e5f",
  fontFamily: "Work Sans, system-ui",
  fontSize: "16px",
  fontWeight: 500,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function CreditCardForm(props) {
  const { formId, onSubmit, onReset } = props;
  const stripe = useStripe();
  const elements = useElements();

  const _handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (error) {
      toast.error(error?.message);
    } else {
      onSubmit(paymentMethod?.id);
    }
  };

  return (
    <form
      className="row fs-6"
      id={formId}
      onSubmit={_handleSubmit}
      onReset={onReset}
    >
      <div className="col-12">
        <small className="text-muted float-end">* Required</small>
      </div>
      <span className="col-12 col-md-6 mt-5 pe-md-4">
        <label htmlFor="card-number" className="form-label">
          Card Number *
        </label>
        <CardNumberElement
          name="cc"
          options={{
            showIcon: true,
            classes: { base: "form-control" },
            style: { base: stripeInputStyles },
          }}
        />
      </span>
      <span className="col-12 col-md-6 mt-5 ps-md-4">
        <label htmlFor="card-cvc" className="form-label">
          CVC *
        </label>
        <CardCvcElement
          name="cvc"
          options={{
            classes: { base: "form-control" },
            style: { base: stripeInputStyles },
          }}
        />
      </span>
      <span className="col-12 col-md-6 mt-5 pe-md-4">
        <label htmlFor="card-exp-date" className="form-label">
          Expiration Date *
        </label>
        <CardExpiryElement
          name="expiry"
          options={{
            classes: { base: "form-control" },
            style: { base: stripeInputStyles },
          }}
        />
      </span>
      <span className="col-12 col-md-6 mt-5 ps-md-4">
        <label htmlFor="billing-zip  " className="form-label">
          Billing Zip *
        </label>
        <input
          type="number"
          className="form-control"
          id="zip"
          name="zip"
          required
        />
      </span>
    </form>
  );
}

CreditCardForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
};
CreditCardForm.defaultProps = {
  onReset: undefined,
};

function CreditCardElements(props) {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm {...props} />
    </Elements>
  );
}
export default CreditCardElements;
