/* eslint-disable react/prop-types */
import React from "react";

export const CircleIcon = (props) => {
  const svgStyles = {
    width: props.width || 12,
    height: props.height || 12,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width={svgStyles.width}
      height={svgStyles.height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="4.75"
        stroke={svgStyles.fill}
        strokeWidth="2.5"
      />
    </svg>
  );
};
