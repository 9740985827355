class RestServiceHelper {
  handleError(err) {
    if (process.env.NODE_ENV === "development") {
      console.log(err); // eslint-disable-line no-console
    }
    const error = err?.response?.data?.message;
    const message = error?.message || error || err.message || "Unknown error";
    return message;
  }
}

export default new RestServiceHelper();
