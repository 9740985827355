/* eslint-disable react/prop-types */
import React from "react";

export const BoxIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9016 9.63678L17.7354 6.44617L19.9016 3.25557C20.0028 3.10339 20.0274 2.91343 19.9685 2.74062C19.909 2.5678 19.7728 2.43218 19.5994 2.37499L12.5291 0.0312592C12.2762 -0.0540136 12.0027 0.0421185 11.8562 0.261844L9.99998 3.04616L8.14374 0.261844C7.99613 0.0409857 7.72031 -0.0545996 7.47082 0.0312592L0.400556 2.37499C0.227198 2.43222 0.0909881 2.56784 0.0314964 2.74062C-0.0274485 2.91343 -0.00283931 3.10339 0.098449 3.25561L2.26461 6.44621L0.0984881 9.63678C-0.00280025 9.78896 -0.0274095 9.97896 0.0315354 10.1518C0.0910272 10.3246 0.227237 10.4602 0.400595 10.5174L7.47086 12.8611C7.71968 12.9448 7.99648 12.8514 8.14378 12.6305L10 9.84623L11.8563 12.6305C12.0037 12.8516 12.2806 12.9448 12.5292 12.8611L19.5994 10.5174C19.7728 10.4602 19.909 10.3245 19.9685 10.1518C20.0274 9.97896 20.0028 9.789 19.9016 9.63678ZM10 8.17253L4.82103 6.44621L10 4.7199L15.179 6.44621L10 8.17253Z"
        fill={svgStyles.fill}
      />
      <path
        d="M12.8998 13.9729C12.1624 14.2204 11.3285 13.9521 10.8811 13.2806L9.99999 11.9589L9.11874 13.2806C8.67222 13.9508 7.83938 14.2215 7.09715 13.9718L2.38281 12.4005V15.8211C2.38281 16.0735 2.54418 16.2972 2.78336 16.3768L9.81112 18.7194C9.83229 18.7268 9.85346 18.7325 9.87463 18.7371C9.87463 18.7371 9.87522 18.7371 9.87522 18.7377H9.87581C9.91702 18.7463 9.95877 18.7508 9.99999 18.7508C10.044 18.7508 10.0875 18.7457 10.131 18.736C10.1505 18.7314 10.1693 18.7262 10.1882 18.7199L17.2166 16.3768C17.4557 16.2972 17.6171 16.0735 17.6171 15.8211V12.4005L12.8998 13.9729Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
