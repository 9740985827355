import React, { useState } from "react";
import { InvisibleIcon, VisibleIcon } from "components/svg";
import PropTypes from "prop-types";

const SecureInput = (props) => {
  const { label, inputProps } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="position-relative">
      {label && (
        <label htmlFor={inputProps?.id} className="form-label">
          {label}
        </label>
      )}
      <input
        type={isVisible ? "text" : "password"}
        className="form-control"
        {...inputProps}
      />
      <span
        className="position-absolute text-muted px-4 py-2"
        style={{
          right: 0,
          bottom: 0,
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={() => setIsVisible((state) => !state)}
      >
        {isVisible ? (
          <VisibleIcon width={20} height={16} />
        ) : (
          <InvisibleIcon width={20} height={16} />
        )}
      </span>
    </div>
  );
};
SecureInput.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
SecureInput.defaultProps = {
  label: undefined,
};
export default SecureInput;
