/* eslint-disable react/prop-types */
export const LockIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
  };

  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C3.477 0 1.5 1.977 1.5 4.5V5.25C0.675 5.25 0 5.925 0 6.75V14.25C0 15.075 0.675 15.75 1.5 15.75H10.5C11.325 15.75 12 15.075 12 14.25V6.75C12 5.925 11.325 5.25 10.5 5.25V4.5C10.5 1.977 8.523 0 6 0ZM6 1.5C7.68225 1.5 9 2.81775 9 4.5V5.25H3V4.5C3 2.81775 4.31775 1.5 6 1.5ZM1.5 6.75H10.5V14.25H1.5V6.75ZM3 9.75C2.80109 9.75 2.61032 9.82902 2.46967 9.96967C2.32902 10.1103 2.25 10.3011 2.25 10.5C2.25 10.6989 2.32902 10.8897 2.46967 11.0303C2.61032 11.171 2.80109 11.25 3 11.25C3.19891 11.25 3.38968 11.171 3.53033 11.0303C3.67098 10.8897 3.75 10.6989 3.75 10.5C3.75 10.3011 3.67098 10.1103 3.53033 9.96967C3.38968 9.82902 3.19891 9.75 3 9.75ZM6 9.75C5.80109 9.75 5.61032 9.82902 5.46967 9.96967C5.32902 10.1103 5.25 10.3011 5.25 10.5C5.25 10.6989 5.32902 10.8897 5.46967 11.0303C5.61032 11.171 5.80109 11.25 6 11.25C6.19891 11.25 6.38968 11.171 6.53033 11.0303C6.67098 10.8897 6.75 10.6989 6.75 10.5C6.75 10.3011 6.67098 10.1103 6.53033 9.96967C6.38968 9.82902 6.19891 9.75 6 9.75ZM9 9.75C8.80109 9.75 8.61032 9.82902 8.46967 9.96967C8.32902 10.1103 8.25 10.3011 8.25 10.5C8.25 10.6989 8.32902 10.8897 8.46967 11.0303C8.61032 11.171 8.80109 11.25 9 11.25C9.19891 11.25 9.38968 11.171 9.53033 11.0303C9.67098 10.8897 9.75 10.6989 9.75 10.5C9.75 10.3011 9.67098 10.1103 9.53033 9.96967C9.38968 9.82902 9.19891 9.75 9 9.75Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
