import React from "react";

const About = () => {
  return (
    <div>
      <h3>Pupple</h3>
      <div className="d-flex">
        <div className="flex-grow-1 purple-100">100</div>
        <div className="flex-grow-1 purple-200">200</div>
        <div className="flex-grow-1 purple-300">300</div>
        <div className="flex-grow-1 purple-400">400</div>
        <div className="flex-grow-1 purple-500">500</div>
        <div className="flex-grow-1 purple-600">600</div>
        <div className="flex-grow-1 purple-700">700</div>
        <div className="flex-grow-1 purple-800">800</div>
        <div className="flex-grow-1 purple-900">900</div>
      </div>
      <h3>Indigo</h3>
      <div className="d-flex">
        <div className="flex-grow-1 indigo-100">100</div>
        <div className="flex-grow-1 indigo-200">200</div>
        <div className="flex-grow-1 indigo-300">300</div>
        <div className="flex-grow-1 indigo-400">400</div>
        <div className="flex-grow-1 indigo-500">500</div>
        <div className="flex-grow-1 indigo-600">600</div>
        <div className="flex-grow-1 indigo-700">700</div>
        <div className="flex-grow-1 indigo-800">700</div>
        <div className="flex-grow-1 indigo-900">700</div>
      </div>
    </div>
  );
};

export default About;
