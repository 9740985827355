import React from "react";
import PropTypes from "prop-types";

import { EnterIcon } from "../svg";

const SubcribeProfileForm = (props) => {
  const { system, isSubmitting, onSubmit } = props;
  return (
    <form className="w-100 align-items-end d-flex gap-4" onSubmit={onSubmit}>
      <span className="flex-grow-1">
        <label htmlFor={`email-${system}`} className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control border-top-0 border-start-0 border-end-0"
          id={`email-${system}`}
          name={system}
          required
        />
      </span>
      <button
        type="submit"
        className="btn btn-sm btn-outline-light"
        disabled={isSubmitting}
      >
        <EnterIcon />
        <span className="ms-2">Submit</span>
      </button>
    </form>
  );
};
SubcribeProfileForm.propTypes = {
  system: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default SubcribeProfileForm;
