import React from "react";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import { CloseIcon, UnsubscribeIcon } from "../svg";

const UnsubscribeProfileModal = (props) => {
  const { target, isSubmitting, defaultEmail, onConfirmed } = props;

  const _submitForm = (event) => {
    event.preventDefault();
    onConfirmed(event);
    document.getElementById(`${target}-close`)?.click();
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-light"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#${target}`}
        disabled={isSubmitting}
      >
        <UnsubscribeIcon />
        <span className="ms-2">Unsubscribe</span>
      </button>
      <ActionModal
        maxWidth={430}
        target={target}
        title={
          <>
            Are{" "}
            <span className="text-lowercase">
              you sure you want to unsubscribe?
            </span>
          </>
        }
      >
        <form onSubmit={_submitForm}>
          <div className="modal-body">
            <label htmlFor={"unsubcribe-email"} className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control border-top-0 border-start-0 border-end-0"
              id={"unsubcribe-email"}
              name={"unsubcribe-email"}
              defaultValue={defaultEmail}
              required
              autoFocus
            />
          </div>
          <div className="modal-footer border-top-0">
            <button
              type="submit"
              data-bs-target={`#${target}`}
              className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <UnsubscribeIcon width={12} height={12} />
              )}
              <span className="ms-2">Unsubscribe</span>
            </button>
            <button
              type="button"
              data-bs-dismiss="modal"
              data-bs-target={`#${target}`}
              aria-label="Close"
              className="btn btn-sm btn-outline-light d-flex align-items-center"
              disabled={isSubmitting}
            >
              <CloseIcon width={12} height={12} />
              <span className="ms-2">No, Keep It</span>
            </button>
          </div>
        </form>
      </ActionModal>
    </>
  );
};
UnsubscribeProfileModal.propTypes = {
  target: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  defaultEmail: PropTypes.string.isRequired,
  onConfirmed: PropTypes.func.isRequired,
};
export default UnsubscribeProfileModal;
