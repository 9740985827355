import React from "react";
import ActionModal from "components/shared/ActionModal";
import PropTypes from "prop-types";

const EarlyAdopterPricingModal = (props) => {
  const { target, okBtnProps, okBtnIcon } = props;
  return (
    <ActionModal
      target={target}
      title="Early Adopter Pricing"
      cancelBtnProps={{
        title: "Cancel",
      }}
      okBtnProps={okBtnProps}
      okBtnIcon={okBtnIcon}
    >
      <div className="modal-body text-primary normal_p" style={{ lineHeight: "1.5rem" }}>
        For a limited time, we are offering our best plan at the same price as the Marketer Lite plan. For those who opt-in, you will be considered our Early Adopters as we value feed back on our newest released features.
      </div>
    </ActionModal>
  );
};

EarlyAdopterPricingModal.propTypes = {
  target: PropTypes.string.isRequired,
  okBtnProps: PropTypes.objectOf(PropTypes.any).isRequired,
  okBtnIcon: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EarlyAdopterPricingModal;
