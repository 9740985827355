/* eslint-disable react/prop-types */
import React from "react";

export const BinIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.14076 0.620745H4.85943V0.909935H5.48009V0.580262C5.48017 0.260318 5.22001 0 4.90022 0H3.09997C2.78018 0 2.52002 0.260318 2.52002 0.580262V0.909935H3.14076V0.620745Z"
        fill={svgStyles.fill}
      />
      <path
        d="M6.9134 3.36922H1.08662C0.926959 3.36922 0.801273 3.50542 0.814139 3.66461L1.30127 9.68816C1.32842 10.0244 1.6089 10.2834 1.94587 10.2834H6.05407C6.39104 10.2834 6.67152 10.0244 6.69867 9.68809L7.1858 3.66461C7.19875 3.50542 7.07306 3.36922 6.9134 3.36922ZM2.4975 9.64093C2.49098 9.64133 2.48447 9.64156 2.47804 9.64156C2.31532 9.64156 2.17873 9.51485 2.16861 9.35025L1.86333 4.40524C1.85282 4.23413 1.98298 4.08686 2.15401 4.07635C2.3245 4.06599 2.47239 4.19584 2.4829 4.36703L2.7881 9.31205C2.79869 9.48316 2.66853 9.63034 2.4975 9.64093ZM4.31383 9.33119C4.31383 9.50254 4.17489 9.64148 4.00346 9.64148C3.83203 9.64148 3.69309 9.50254 3.69309 9.33119V4.3861C3.69309 4.21467 3.83203 4.07572 4.00346 4.07572C4.17481 4.07572 4.31383 4.21467 4.31383 4.3861V9.33119ZM6.13669 4.40438L5.84522 9.34939C5.83557 9.51431 5.69874 9.64148 5.53571 9.64148C5.52959 9.64148 5.52339 9.64133 5.51719 9.64101C5.34608 9.63089 5.21553 9.48402 5.22565 9.31291L5.51704 4.36781C5.52708 4.1967 5.67348 4.06615 5.84506 4.07627C6.01618 4.08631 6.14673 4.23326 6.13669 4.40438Z"
        fill={svgStyles.fill}
      />
      <path
        d="M7.98667 2.41137L7.78284 1.80036C7.7291 1.63929 7.57831 1.53062 7.40845 1.53062H0.591471C0.421691 1.53062 0.27082 1.63929 0.217156 1.80036L0.013327 2.41137C-0.0259796 2.52921 0.0251739 2.64941 0.120655 2.70935C0.159569 2.73375 0.205623 2.74842 0.256227 2.74842H7.74377C7.79438 2.74842 7.84051 2.73375 7.87934 2.70927C7.97483 2.64933 8.02598 2.52914 7.98667 2.41137Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
