import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import CancelInviteUserModal from "components/CancelInviteUserModal";
import ConfirmInviteUserModal from "components/ConfirmInviteUserModal";
import Select from "components/shared/Select";
import { BinIcon, SaveIcon, SendIcon } from "components/svg";
import {
  DATE_TIME_FORMAT,
  INVITE_USER_STATUS,
  ROLE_OPTIONS,
  SYSTEM_LOGO,
  SYSTEMS,
} from "constant";
import { useUser } from "context/User";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import restServiceHelper from "helpers/restServiceHelper";

dayjs.extend(advancedFormat);
const Detail = () => {
  const params = useParams();
  const { email } = params;
  const { user } = useUser();
  const [userDetail, setUserDetail] = useState({});
  const [platform, setPlatform] = useState({
    [SYSTEMS.stream]: ROLE_OPTIONS[0],
    [SYSTEMS.ecom]: ROLE_OPTIONS[0],
    [SYSTEMS.launch]: ROLE_OPTIONS[0],
  });
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const _getUserDetail = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/get-user`,
        { email }
      );
      if (res?.data?.success) {
        const { record } = res?.data;
        const initPlatform = { ...platform };
        if (record?.platforms) {
          record.platforms.forEach((item) => {
            const initRole =
              ROLE_OPTIONS.find((option) => option.value === item?.role) ||
              ROLE_OPTIONS[0];
            initPlatform[item?.name] = { ...item, ...initRole };
          });
        }
        setPlatform(initPlatform);
        setUserDetail(record);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    _getUserDetail();
  }, []); // eslint-disable-line

  const _closeModal = (id) => {
    const modalToggle = document.getElementById(`${id}-close`);
    if (modalToggle) {
      modalToggle.click();
    }
  };

  const _askToInviteUser = (data) => {
    setSelectedPlatform(data);
  };

  const _askToCancelInviteUser = (name) => {
    setSelectedPlatform({ name });
  };

  const _inviteUser = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      let invitePlatform;
      if (selectedPlatform) {
        invitePlatform = Object.keys(selectedPlatform).map((key) => ({
          platform: key,
          role: selectedPlatform[key]?.value,
        }));
      }
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/invite`,
        { email, data: invitePlatform }
      );
      if (res?.data?.success) {
        _getUserDetail();
        _closeModal("confirm-invite-user-detail-modal");
        toast.success("Invite sent successfully");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _cancelInviteUser = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const { name } = selectedPlatform;
      if (!name) {
        return;
      }
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/cancel-invite`,
        { email, system: name }
      );
      if (res?.data?.success) {
        setPlatform((state) => {
          const canceledPlatform = state[name];
          return {
            ...state,
            [name]: { ...canceledPlatform, status: undefined },
          };
        });
        _closeModal("cancel-invite-user-detail-modal");
        toast.success("Invite cancelled");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _resendInviteUser = async (platformName) => {
    try {
      setIsSubmitting(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/resend-invite`,
        { email, system: platformName, account_id: user?.account_id }
      );
      if (res?.data?.success) {
        toast.success("Invite resent successfully");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _updateUser = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/update-user`,
        {
          email,
          account_id: user?.account_id,
          data: Object.values(platform).reduce((accumulator, currentValue) => {
            if (currentValue?.role) {
              accumulator.push({
                role: currentValue.value,
                platform: currentValue.name,
                status: currentValue.status,
              });
            }
            return accumulator;
          }, []),
        }
      );
      if (res?.data?.success) {
        toast.success("User saved successfully");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _changePlatformValues = (name, values) => {
    setPlatform((state) => ({
      ...state,
      [name]: { ...platform[name], ...values },
    }));
  };

  const _renderPlatformSettings = (platformName) => {
    const currPlatform = platform[platformName];
    const status = currPlatform?.status;
    const isActive = status === INVITE_USER_STATUS.ACTIVE.name;
    return (
      <div className="card mb-4">
        <div className="p-4 border-bottom mb-4 text-primary">
          {SYSTEM_LOGO[platformName]}
        </div>
        <div className="d-flex flex-wrap align-items-center gap-4 gap-md-5 p-4">
          <div className="flex-grow-1" style={{ maxWidth: 207 }}>
            <label htmlFor="launchRole" className="form-label">
              Role
            </label>
            <Select
              defaultValue={currPlatform || ROLE_OPTIONS[0]}
              options={ROLE_OPTIONS}
              onChange={(option) => _changePlatformValues(platformName, option)}
            />
          </div>

          {(status === INVITE_USER_STATUS.ACTIVE.name ||
            status === INVITE_USER_STATUS.INACTIVE.name) && (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name={platformName}
                id={`${platformName}-checkbox`}
                checked={isActive}
                onChange={() =>
                  _changePlatformValues(platformName, {
                    status: isActive
                      ? INVITE_USER_STATUS.INACTIVE.name
                      : INVITE_USER_STATUS.ACTIVE.name,
                  })
                }
              />
              <label
                className="form-check-label fw-500 ps-2"
                htmlFor={`${platformName}-checkbox`}
              >
                {isActive ? (
                  <span className="text-secondary">Active</span>
                ) : (
                  <span className="text-muted">Inactive</span>
                )}
              </label>
            </div>
          )}
          {status === INVITE_USER_STATUS.PENDING.name && (
            <>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm rounded-pill fw-500 flex-shrink-0"
                data-bs-toggle="modal"
                data-bs-target={"#cancel-invite-user-detail-modal"}
                disabled={isSubmitting}
                onClick={() => _askToCancelInviteUser(platformName)}
              >
                <span className="pe-1">Pending Invite</span>
                <BinIcon width={8} height={11} />
              </button>
              <button
                type="button"
                className="btn btn-outline-light btn-sm flex-shrink-0"
                disabled={isSubmitting}
                onClick={() => _resendInviteUser(platformName)}
              >
                <SendIcon width={12} height={11} />
                <span className="ps-1">Resend</span>
              </button>
            </>
          )}
          {status === undefined && (
            <button
              type="button"
              className="btn btn-outline-light btn-sm flex-shrink-0"
              data-bs-toggle="modal"
              data-bs-target={"#confirm-invite-user-detail-modal"}
              disabled={isSubmitting}
              onClick={() => _askToInviteUser({ [platformName]: currPlatform })}
            >
              <SendIcon width={12} height={11} />
              <span className="ps-1">Invite</span>
            </button>
          )}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={_updateUser}>
        <div
          className="bg-white border-bottom text-end px-3 px-md-4 px-lg-5"
          style={{ padding: 10, marginBottom: 30 }}
        >
          {isSubmitting ? (
            <button
              type="button"
              className="btn btn-secondary btn-sm justify-content-center"
              style={{ width: 72, height: 30 }}
            >
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </button>
          ) : (
            <button type="submit" className="btn btn-outline-secondary btn-sm">
              <SaveIcon width={12} height={12} />
              <span className="ps-2">Save</span>
            </button>
          )}
        </div>
        <div className="mx-3 mx-md-4 mx-lg-5">
          <div className="card p-4 mb-4 text-primary">
            <p className="fs-6-lg fw-semibold">Basic Info</p>
            <div className="d-flex justify-content-between fw-500">
              <div>
                <label className="d-block text-muted mb-2">First Name</label>
                <label>{userDetail?.first_name || "-"}</label>
              </div>
              <div>
                <label className="d-block text-muted mb-2">Last Name</label>
                <label>{userDetail?.last_name || "-"}</label>
              </div>
              <div>
                <label className="d-block text-muted mb-2">Email</label>
                <label>{userDetail?.email || "-"}</label>
              </div>
              <div>
                <label className="d-block text-muted mb-2">Date Joined</label>

                <label>
                  {userDetail?.verified_at
                    ? dayjs(userDetail.verified_at * 1000).format(
                        DATE_TIME_FORMAT.MONTH_DAY_ORDINAL_YEAR
                      )
                    : "-"}
                </label>
              </div>
            </div>
          </div>
          {_renderPlatformSettings(SYSTEMS.stream)}

          {/* TEMPORARY COMMENT FOR LATER USE */}

          {/* {_renderPlatformSettings(SYSTEMS.ecom)}
          {_renderPlatformSettings(SYSTEMS.launch)} */}
        </div>
      </form>
      <ConfirmInviteUserModal
        target="confirm-invite-user-detail-modal"
        isSubmitting={isSubmitting}
        email={email}
        role={selectedPlatform}
        onConfirmed={_inviteUser}
      />
      <CancelInviteUserModal
        target="cancel-invite-user-detail-modal"
        isSubmitting={isSubmitting}
        email={email}
        platform={selectedPlatform}
        onConfirmed={_cancelInviteUser}
      />
    </>
  );
};

export default Detail;
