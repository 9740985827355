/* eslint-disable react/prop-types */
export const CardOutlineIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
  };

  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4651 9.76744H1.53488C1.12781 9.76744 0.737404 9.60573 0.449557 9.31788C0.161711 9.03004 0 8.63963 0 8.23256V1.53488C0 1.12781 0.161711 0.737403 0.449557 0.449557C0.737404 0.16171 1.12781 0 1.53488 0H10.4651C10.8722 0 11.2626 0.16171 11.5504 0.449557C11.8383 0.737403 12 1.12781 12 1.53488V8.23256C12 8.63963 11.8383 9.03004 11.5504 9.31788C11.2626 9.60573 10.8722 9.76744 10.4651 9.76744ZM1.53488 0.837209C1.34985 0.837209 1.17239 0.910714 1.04155 1.04155C0.910714 1.17239 0.837209 1.34985 0.837209 1.53488V8.23256C0.837209 8.41759 0.910714 8.59505 1.04155 8.72589C1.17239 8.85673 1.34985 8.93023 1.53488 8.93023H10.4651C10.6502 8.93023 10.8276 8.85673 10.9584 8.72589C11.0893 8.59505 11.1628 8.41759 11.1628 8.23256V1.53488C11.1628 1.34985 11.0893 1.17239 10.9584 1.04155C10.8276 0.910714 10.6502 0.837209 10.4651 0.837209H1.53488Z"
        fill="white"
      />
      <path
        d="M4.32597 7.814H2.09341C1.98284 7.81255 1.8772 7.76798 1.79901 7.68979C1.72082 7.6116 1.67625 7.50596 1.6748 7.39539V5.72097C1.67625 5.6104 1.72082 5.50477 1.79901 5.42657C1.8772 5.34838 1.98284 5.30381 2.09341 5.30237H4.32597C4.43654 5.30381 4.54217 5.34838 4.62037 5.42657C4.69856 5.50477 4.74313 5.6104 4.74457 5.72097V7.39539C4.74313 7.50596 4.69856 7.6116 4.62037 7.68979C4.54217 7.76798 4.43654 7.81255 4.32597 7.814ZM2.51201 6.97679H3.90736V6.13958H2.51201V6.97679Z"
        fill="white"
      />
      <path
        d="M7.11594 7.53484H5.99966C5.88864 7.53484 5.78216 7.49074 5.70366 7.41223C5.62516 7.33373 5.58105 7.22726 5.58105 7.11624C5.58105 7.00522 5.62516 6.89874 5.70366 6.82024C5.78216 6.74173 5.88864 6.69763 5.99966 6.69763H7.11594C7.22696 6.69763 7.33343 6.74173 7.41194 6.82024C7.49044 6.89874 7.53454 7.00522 7.53454 7.11624C7.53454 7.22726 7.49044 7.33373 7.41194 7.41223C7.33343 7.49074 7.22696 7.53484 7.11594 7.53484Z"
        fill="white"
      />
      <path
        d="M9.90695 7.53484H8.79067C8.67965 7.53484 8.57318 7.49074 8.49468 7.41223C8.41617 7.33373 8.37207 7.22726 8.37207 7.11624C8.37207 7.00522 8.41617 6.89874 8.49468 6.82024C8.57318 6.74173 8.67965 6.69763 8.79067 6.69763H9.90695C10.018 6.69763 10.1244 6.74173 10.203 6.82024C10.2815 6.89874 10.3256 7.00522 10.3256 7.11624C10.3256 7.22726 10.2815 7.33373 10.203 7.41223C10.1244 7.49074 10.018 7.53484 9.90695 7.53484Z"
        fill="white"
      />
      <path
        d="M0.418945 2.65112H11.5817V3.7674H0.418945V2.65112Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
