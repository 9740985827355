import React, { useEffect, useState } from "react";
import { CoinIcon, LayerIcon, SettingsIcon, UsersIcon } from "components/svg";
import { SYSTEM_ROLE } from "constant";
import { useUser } from "context/User";
import PropTypes from "prop-types";

import Header from "./Admin/Header";
import Navbar from "./Admin/Navbar";

import "./index.scss";

const BaseLayout = (props) => {
  const { children } = props;
  const { user } = useUser();
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (user.role === SYSTEM_ROLE.ZI_ADMIN) {
      setRoutes([
        {
          path: "/",
          title: "Access",
          icon: <LayerIcon width={24} height={24} />,
        },
        {
          path: "/user",
          title: "Users",
          icon: <UsersIcon width={24} height={24} />,
        },
        {
          path: "/billing",
          title: "Billing",
          icon: <CoinIcon width={24} height={24} />,
        },
        {
          path: "/account",
          title: "Account",
          icon: <SettingsIcon width={24} height={24} />,
        },
      ]);
    }

    if (user.role === SYSTEM_ROLE.ZI_ADMIN && user.plan === "PERSONAL") {
      setRoutes([
        {
          path: "/",
          title: "Access",
          icon: <LayerIcon width={24} height={24} />,
        },
        {
          path: "/account",
          title: "Account",
          icon: <SettingsIcon width={24} height={24} />,
        },
      ]);
    }

    if (user.role === SYSTEM_ROLE.ZI_USER) {
      setRoutes([
        {
          path: "/",
          title: "Account",
          icon: <SettingsIcon width={24} height={24} />,
        },
      ]);
    }
  }, [user.role]);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Navbar routes={routes} />
          <main className="ms-sm-auto flex-grow-1 p-0">{children}</main>
        </div>
      </div>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.any,
};
BaseLayout.defaultProps = {
  children: undefined,
};
export default BaseLayout;
