import React, { useState } from "react";
import PropTypes from "prop-types";

import "./index.scss";

function CreditSliderBar(props) {
  const { list, selectedItem, onSelectItem } = props;
  const rangeLength = list.length;
  const [fillPercent, setFillPercent] = useState(rangeLength / 2);

  const _handleRangeChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setFillPercent((value / rangeLength) * 100 + rangeLength / 2);
    onSelectItem(list[value]);
  };

  if (!list || list?.length < 1) {
    return null;
  }

  return (
    <>
      <div className="credit-slider">
        <input
          type="range"
          name="creditSlider"
          data-keeper-edited="yes"
          step={1}
          min={0}
          max={rangeLength - 1}
          defaultValue={0}
          list="volumn"
          onChange={_handleRangeChange}
          style={{
            width: `calc(100% - (100%/${rangeLength})/1.5)`,
          }}
        />
        <datalist id="volumn">
          {list &&
            list.map((range, rIndex) => {
              return (
                <option
                  key={range?.plan_id}
                  value={rIndex}
                  label={selectedItem?.tb === range?.tb ? "" : range?.tb}
                  style={{ width: `calc(100% / ${rangeLength})` }}
                ></option>
              );
            })}
        </datalist>
        <div
          className="credit-slider__fill-bar"
          style={{
            backgroundSize: `${fillPercent}% 100%`,
          }}
        >
          <div
            className="credit-slider__fill-bar__credit-wrap"
            style={{ left: fillPercent + "%" }}
          >
            <span className="credit-slider__fill-bar__credit-wrap__price">
              ${selectedItem?.purchase_price}
            </span>
            <span
              className={`credit-slider__fill-bar__credit-wrap__storage ${
                fillPercent < 10 ? "left-edge" : ""
              } ${fillPercent > 90 ? "right-edge" : ""}`}
            >
              <p className="mb-1 d-flex flex-wrap gap-2 align-items-center">
                <span className="flex-shrink-0">{selectedItem?.name}</span>
                <small>
                  {(selectedItem?.cost_per_gb * 100).toFixed(0)}¢/GB
                </small>
              </p>
              <small className="text-white">
                {selectedItem?.savings || 0}% Savings
              </small>
            </span>
          </div>
        </div>
      </div>
      <p className="mb-4 pb-2" style={{ paddingTop: 103 }}>
        ${selectedItem?.purchase_price}
        <small className="text-muted fs-8 fw-500 ps-2">
          total charged today
        </small>
      </p>
    </>
  );
}

CreditSliderBar.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.objectOf(PropTypes.any),
  onSelectItem: PropTypes.func.isRequired,
};
CreditSliderBar.defaultProps = {
  list: [],
  selectedItem: {},
};

export default CreditSliderBar;
