import React, { useState } from "react";
import { errorCode, ROLE_OPTIONS, SYSTEM_LOGO, SYSTEMS } from "constant";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import Select from "../shared/Select";
import { CloseIcon, EnterIcon } from "../svg";

const InviteUserModal = (props) => {
  const { target, confirmTarget, errCode, onSubmit } = props;
  const [role, setRole] = useState({
    [SYSTEMS.stream]: ROLE_OPTIONS[0],
    [SYSTEMS.ecom]: ROLE_OPTIONS[0],
    [SYSTEMS.launch]: ROLE_OPTIONS[0],
  });
  const isInvalidEmail = errCode === errorCode.INVITE_EXIST_USER;

  const _handleChangeRole = (system, option) => {
    setRole((state) => ({ ...state, [system]: option }));
  };

  const _handleSubmit = (event) => {
    event.preventDefault();
    let email;
    const selectedRole = {};
    const formData = new FormData(event.target);
    for (const [name, value] of formData) {
      if (name === "email") {
        email = value;
      } else {
        selectedRole[name] = role[name];
      }
    }
    onSubmit(email, selectedRole);
  };

  return (
    <ActionModal maxWidth={600} target={target} title="Add User">
      <form onSubmit={_handleSubmit}>
        <div className="modal-body text-start">
          <label
            htmlFor={"user-email"}
            className={`form-label ${isInvalidEmail ? "text-danger" : ""}`}
          >
            Email
          </label>
          <input
            type="email"
            className={`form-control border-top-0 border-start-0 border-end-0 mb-5 ${
              isInvalidEmail ? "is-invalid" : ""
            }`}
            id={"user-email"}
            name="email"
            required
            autoFocus
          />
          <label className="form-label">Platforms</label>
          <div className="d-flex gap-2 align-items-end text-primary pb-4 mb-3">
            <input
              className="form-check-input flex-shrink-0"
              type="checkbox"
              name={SYSTEMS.stream}
              id="streamCheckbox"
            />
            <label htmlFor="streamCheckbox">
              {SYSTEM_LOGO[SYSTEMS.stream]}
            </label>
            <div className="ps-5 flex-grow-1">
              <label htmlFor="streamRole" className="form-label">
                Role
              </label>
              <Select
                defaultValue={ROLE_OPTIONS[0]}
                options={ROLE_OPTIONS}
                onChange={(option) => _handleChangeRole(SYSTEMS.stream, option)}
              />
            </div>
          </div>

          {/*

            THIS SECTION IS TEMPORARY HIDE FOR LATER USE

          */}
          {/* <div className="d-flex gap-2 align-items-end text-primary pb-4 mb-3">
            <input
              className="form-check-input flex-shrink-0"
              type="checkbox"
              name={SYSTEMS.ecom}
              id="ecomCheckbox"
            />
            <label htmlFor="ecomCheckbox">{SYSTEM_LOGO[SYSTEMS.ecom]}</label>
            <div className="ps-5 flex-grow-1">
              <label htmlFor="ecomRole" className="form-label">
                Role
              </label>
              <Select
                defaultValue={ROLE_OPTIONS[0]}
                options={ROLE_OPTIONS}
                onChange={(option) => _handleChangeRole(SYSTEMS.ecom, option)}
              />
            </div>
          </div> */}
          {/* <div className="d-flex gap-2 align-items-end text-primary pb-4 mb-3">
            <input
              className="form-check-input flex-shrink-0"
              type="checkbox"
              name={SYSTEMS.launch}
              id="launchCheckbox"
            />
            <label htmlFor="launchCheckbox">
              {SYSTEM_LOGO[SYSTEMS.launch]}
            </label>
            <div className="ps-5 flex-grow-1">
              <label htmlFor="launchRole" className="form-label">
                Role
              </label>
              <Select
                defaultValue={ROLE_OPTIONS[0]}
                options={ROLE_OPTIONS}
                onChange={(option) => _handleChangeRole(SYSTEMS.launch, option)}
              />
            </div>
          </div> */}
        </div>
        <div className="modal-footer">
          <button
            type="reset"
            data-bs-dismiss="modal"
            data-bs-target={`#${target}`}
            aria-label="Close"
            className="btn btn-sm btn-outline-light d-flex align-items-center"
          >
            <CloseIcon width={12} height={12} />
            <span className="ms-2">Cancel</span>
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
            data-bs-toggle="modal"
            data-bs-target={`#${confirmTarget}`}
          >
            <EnterIcon width={12} height={12} />
            <span className="ms-2">Submit</span>
          </button>
        </div>
      </form>
    </ActionModal>
  );
};

InviteUserModal.propTypes = {
  target: PropTypes.string.isRequired,
  confirmTarget: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errCode: PropTypes.string,
};
InviteUserModal.defaultProps = {
  errCode: undefined,
};
export default InviteUserModal;
