/* eslint-disable react/prop-types */
import React from "react";

export const SendIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.14857 1.73143L5.44 3.57143L1.14286 3L1.14857 1.73143ZM5.43429 6.71429L1.14286 8.55429V7.28571L5.43429 6.71429ZM0.00571428 0L0 4L8.57143 5.14286L0 6.28571L0.00571428 10.2857L12 5.14286L0.00571428 0Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
