import React, { useEffect, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { ChevronUpIcon } from "components/svg";
import PropTypes from "prop-types";

const EarlyAdopterPricingPopover = (props) => {
  const { popoverTarget, modalTarget } = props;
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <Popover
      isOpen={isOpen}
      placement="top"
      offset={[94, 7]}
      trigger="hover"
      target={popoverTarget}
      className="early_adopter_pop"
    >
      <PopoverBody className="shadow">
        <div className="d-flex justify-content-between pb-2 mb-1">
          <span className="fw-bold pe-4">Early Adopter Price</span>
          <s className="fw-500 opacity-75">$249</s>
        </div>
        <a
          href="#"
          className="d-flex align-items-center gap-2 fs-8 fw-semibold text-white"
          data-bs-toggle="modal"
          data-bs-target={`#${modalTarget}`}
          style={{ letterSpacing: "0.1em" }}
        >
          LEARN MORE
          <ChevronUpIcon width={9} height={9} transform="rotate(90deg)" />
        </a>
      </PopoverBody>
    </Popover>
  );
};

EarlyAdopterPricingPopover.propTypes = {
  popoverTarget: PropTypes.string.isRequired,
  modalTarget: PropTypes.string.isRequired,
};

export default EarlyAdopterPricingPopover;
