import React from "react";
import MainTable from "components/shared/MainTable";
import { CircleIcon, CloseIcon, TickIcon } from "components/svg";
import { DATE_TIME_FORMAT } from "constant";
import { utcToLocal } from "helpers/functions";
import PropTypes from "prop-types";

const columns = [
  {
    Header: "Date",
    accessor: (row) =>
      utcToLocal(row.created_at * 1000, DATE_TIME_FORMAT.COMMON_MONTH_DAY_YEAR),
  },
  {
    Header: "Platform",
    accessor: "platform",
  },
  {
    Header: "Transcation Type",
    accessor: "type",
  },
  {
    Header: "Billing Cycle",
    accessor: (row) =>
      row.end_date
        ? utcToLocal(row.end_date * 1000, DATE_TIME_FORMAT.COMMON_MONTH_YEAR)
        : "N/A",
  },
  {
    Header: "Amount",
    accessor: (row) =>
      typeof row.amount === "number" ? `$${(row.amount / 100)?.toFixed(2)}` : 0,
  },
  {
    Header: "Status",
    accessor: "status",
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => {
      // eslint-disable-next-line react/prop-types
      const { status } = row.original;
      const statusClassName =
        "billing-wrapper__btn-status btn btn-sm rounded-pill py-1 fw-500";
      if (["paid", "succeeded"].includes(status)) {
        return (
          <div
            className={`${statusClassName} btn-outline-success text-success`}
          >
            <TickIcon width={12} height={9} />
            <span className="ps-1 text-capitalize">{status}</span>
          </div>
        );
      }
      if (status === "pending") {
        return (
          <div
            className={`${statusClassName} btn-outline-warning text-warning`}
          >
            <CircleIcon width={12} height={12} />
            <span className="ps-1 text-capitalize">{status}</span>
          </div>
        );
      }
      return (
        <div className={`${statusClassName} btn-outline-danger text-danger`}>
          <CloseIcon width={12} height={9} />
          <span className="ps-1 text-capitalize">{status}</span>
        </div>
      );
    },
  },
  {
    Header: "",
    accessor: "download",
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => {
      // eslint-disable-next-line react/prop-types
      const { invoice_pdf } = row.original;
      return (
        <a
          href={invoice_pdf}
          className="btn btn-sm btn-outline-secondary rounded-pill px-4 py-1 fw-500 float-end"
        >
          Download
        </a>
      );
    },
  },
];

const InvoiceHistoryTable = (props) => {
  const { data, isLoading } = props;
  return (
    <MainTable
      name="Invoices"
      columns={columns}
      data={data}
      isLoading={isLoading}
      onCellClick={() => {}}
    />
  );
};

InvoiceHistoryTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool,
};
InvoiceHistoryTable.defaultProps = {
  data: [],
  isLoading: true,
};
export default InvoiceHistoryTable;
