/* eslint-disable react/prop-types */
import React from "react";

export const UnsubscribeIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33125 0C0.603682 0 0 0.603682 0 1.33125V9.9844H1.33125V1.33125H11.9813V0H1.33125ZM3.99376 2.66251C3.25957 2.66251 2.66251 3.25957 2.66251 3.99376V10.65C2.66251 11.3842 3.25957 11.9813 3.99376 11.9813H9.15757L9.35257 11.7863L9.82319 11.3157L9.35257 10.845L9.15757 10.65H3.99376V5.74233L8.65315 8.65315L13.3125 5.74233L13.3138 7.82631L14.6438 6.49506V3.99376C14.6438 3.25957 14.0467 2.66251 13.3125 2.66251H3.99376ZM3.99376 3.99376H13.3125V4.41108L8.65315 7.32189L3.99376 4.41108V3.99376ZM11.2351 8.96256L10.2938 9.9038L11.7057 11.3157L10.2938 12.7275L11.2351 13.6687L12.6469 12.2569L14.0588 13.6687L15 12.7275L13.5881 11.3157L15 9.9038L14.0588 8.96256L12.6469 10.3744L11.2351 8.96256Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
