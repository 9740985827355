import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const Navbar = (props) => {
  const { routes } = props;

  return (
    <nav
      id="sidebarMenu"
      className="sidebar navbar navbar-light d-md-block collapse p-0 bg-primary"
    >
      <div className="position-sticky">
        <div className="navbar-brand d-none d-md-flex align-items-center w-100 border-bottom">
          <a className="m-auto" href="/">
            <img src="/zeal_inno.svg" alt="Logo Zeal Innovation" />
          </a>
        </div>
        <ul className="navbar-nav flex-column nav-pills pb-3 px-4">
          {routes.map((route, index) => (
            <li className="nav-item mt-3 mb-2" key={index}>
              <NavLink
                className={({ isActive }) =>
                  `nav-link d-flex align-items-center px-2 overflow-hidden ${
                    isActive ? "active" : ""
                  }`
                }
                aria-current="page"
                to={route.path}
                state={{ title: route.title }}
              >
                <span className="flex-shrink-0">{route.icon}</span>
                <span className="ps-2">{route.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default Navbar;
