/* eslint-disable react/prop-types */
export const ChevronLeftIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
    transform: props.transform,
  };

  return (
    <svg style={svgStyles} viewBox="0 0 24 24">
      <path
        fill={svgStyles.fill}
        d="m16.293 22.707-10-10a1 1 0 0 1 0-1.414l10-10a1 1 0 0 1 1.414 1.414l-9.293 9.293 9.293 9.293a1 1 0 0 1 -1.414 1.414z"
      />
    </svg>
  );
};
