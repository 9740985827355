/* eslint-disable react/prop-types */
import React from "react";

export const CircleCheckIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.25C3.05 0.25 0.25 3.05 0.25 6.5C0.25 9.95 3.05 12.75 6.5 12.75C9.95 12.75 12.75 9.95 12.75 6.5C12.75 3.05 9.95 0.25 6.5 0.25ZM5.25 9.625L2.125 6.5L3.00625 5.61875L5.25 7.85625L9.99375 3.1125L10.875 4L5.25 9.625Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
