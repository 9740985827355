import React from "react";
import Select from "react-select";
import { ArrowDownIcon } from "components/svg";

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      components={{
        DropdownIndicator: () => <ArrowDownIcon />,
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: "0 0 6px 6px",
        colors: {
          ...theme.colors,
          primary: "var(--bs-secondary)",
          primary25: "#F6EBFF",
        },
      })}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          borderRadius: 0,
          boxShadow: "none",
          border: "none",
          borderBottom: "1px solid #9889A4",
          minHeight: 33,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          padding: "11px 10px",
          fontWeight: 500,
        }),
        indicatorsContainer: () => ({ color: "var(--bs-light)" }),
        indicatorSeparator: () => ({ display: "none" }),
        input: (baseStyles) => ({
          ...baseStyles,
          padding: 0,
          margin: 0,
          color: "var(--bs-primary)",
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          marginTop: 0,
          color: "var(--bs-primary)",
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          marginLeft: 0,
          marginTop: 4,
          color: "var(--bs-primary)",
          fontWeight: 500,
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
      }}
    />
  );
};

export default CustomSelect;
