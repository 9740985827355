import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { CheckIcon, CloseIcon } from "../../svg";

const ActionModal = (props) => {
  const {
    children,
    target,
    title,
    maxWidth,
    isOpen,
    cancelBtnProps,
    okBtnProps,
    cancelBtnIcon,
    okBtnIcon,
  } = props;

  useEffect(() => {
    if (target && isOpen === false) {
      document.getElementById(`${target}-close`)?.click();
    }
  }, [isOpen]);

  return (
    <div
      className="modal fade"
      id={target}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title fw-semibold text-primary text-capitalize fs-6-lg"
              id="exampleModalLabel"
            >
              {title}
            </h5>
            <button
              type="button"
              className="btn btn-link text-muted p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              id={`${target}-close`}
            >
              {cancelBtnIcon || <CloseIcon width={20} height={20} />}
            </button>
          </div>
          {children}
          {/* Example modal body and footer */}
          {(cancelBtnProps || okBtnProps) && (
            <div className="modal-footer">
              {cancelBtnProps && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  data-bs-target={target}
                  aria-label="Close"
                  className="btn btn-sm btn-outline-light d-flex align-items-center"
                  {...cancelBtnProps}
                >
                  <CloseIcon width={12} height={12} />
                  <span className="ms-2">
                    {cancelBtnProps?.title || "Cancel"}
                  </span>
                </button>
              )}
              {okBtnProps && (
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={target}
                  className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
                  {...okBtnProps}
                >
                  {okBtnIcon || <CheckIcon width={12} height={12} />}
                  <span className="ms-2">{okBtnProps?.title || "OK"}</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
ActionModal.propTypes = {
  children: PropTypes.any,
  target: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cancelBtnProps: PropTypes.objectOf(PropTypes.any),
  okBtnProps: PropTypes.objectOf(PropTypes.any),
  cancelBtnIcon: PropTypes.any,
  okBtnIcon: PropTypes.any,
  isOpen: PropTypes.bool,
};
ActionModal.defaultProps = {
  children: undefined,
  title: "Modal title",
  cancelBtnProps: undefined,
  okBtnProps: undefined,
  cancelBtnIcon: undefined,
  okBtnIcon: undefined,
  isOpen: undefined,
};
export default ActionModal;
