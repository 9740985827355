/* eslint-disable react/prop-types */
import React from "react";

export const PlusIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 0V5.33333H0V6.66667H5.33333V12H6.66667V6.66667H12V5.33333H6.66667V0H5.33333Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
