import React from "react";
import { useLocation } from "react-router-dom";
import { UserIcon } from "components/svg";
import { SYSTEM_ROLE } from "constant";
import { useUser } from "context/User";

const Header = () => {
  const { user, signOut } = useUser();
  const location = useLocation();
  const { state } = location;

  return (
    <header className="navbar sticky-top flex-md-nowrap">
      <div className="d-flex flex-grow-1 align-items-center justify-content-between bg-white border-bottom h-100 px-3 px-md-5">
        <h4 className="d-none d-md-block mb-0 text-primary fw-semibold">
          {state?.title ||
            (user.role === SYSTEM_ROLE.ZI_ADMIN && "Access") ||
            (user.role === SYSTEM_ROLE.ZI_USER && "Account")}
        </h4>
        <button
          className="navbar-toggler d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="dropdown">
          <div
            className="d-flex align-items-center text-primary dropdown-toggle"
            id="dropdownUserMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="fw-semibold text-end px-2 mx-1">
              <p className="mb-1">{`${user?.first_name} ${user?.last_name}`}</p>
              <small className="text-muted fw-500">{user?.client_name}</small>
            </div>
            <UserIcon width={50} height={50} />
          </div>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownUserMenu"
          >
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => signOut()}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
