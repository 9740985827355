/* eslint-disable react/prop-types */
import React from "react";

export const BankIcon = (props) => {
  const svgStyles = {
    width: props.width || 16,
    height: props.height || 16,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width={svgStyles.width}
      height={svgStyles.height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_2229_11521)">
        <path
          d="M15.3965 4.81738H1.27539L8.33594 1.27246L15.3965 4.81738Z"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.1543 6.5752C2.1543 6.5752 1.27539 5.69629 1.27539 4.81738"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.1543 6.5752H14.5176C14.5176 6.5752 15.3965 5.69629 15.3965 4.81738"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5176 13.6357H2.1543C2.1543 13.6357 1.27539 14.5146 1.27539 15.3936H15.3965C15.3965 14.5146 14.5176 13.6357 14.5176 13.6357Z"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.79102 6.5752V13.6357"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.0332 13.6357V6.5752"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.21484 6.5752V13.6357"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.45703 13.6357V6.5752"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6387 6.5752V13.6357"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8809 13.6357V6.5752"
          stroke={svgStyles.fill}
          strokeWidth="0.878906"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
