/* eslint-disable react/prop-types */
import React from "react";

export const ArrowRightIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
    transform: props.transform,
  };
  return (
    <svg
      viewBox="0 0 10 9"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0L4.20688 0.793125L7.34562 3.9375H0.5V5.0625H7.34562L4.20688 8.20687L5 9L9.5 4.5L5 0Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
