import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import CancelInviteUserModal from "components/CancelInviteUserModal";
import ConfirmInviteUserModal from "components/ConfirmInviteUserModal";
import InviteUserModal from "components/InviteUserModal";
import PlatformStatusTag from "components/PlatformStatusTag";
import MainTable from "components/shared/MainTable";
import { PlusIcon } from "components/svg";
import { closeModal } from "helpers/functions";
import restServiceHelper from "helpers/restServiceHelper";

const User = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState({});
  const [platform, setPlatform] = useState({});
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errCode, setErrCode] = useState();

  const columns = useMemo(
    () => [
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          const { platforms } = props.cell.row.original;
          // eslint-disable-next-line react/prop-types
          if (!platforms || platforms.length === 0) {
            return "-";
          }
          // eslint-disable-next-line react/prop-types
          const firstRole = platforms[0]?.role;
          // eslint-disable-next-line react/prop-types
          const isMultiRole = platforms.some(
            (item) => item?.role !== firstRole
          );
          return (
            <span className="text-capitalize">
              {isMultiRole ? "Multi" : firstRole?.toLowerCase()}
            </span>
          );
        },
      },
      {
        Header: "Platform Access",
        accessor: "platforms",
        maxWidth: 430,
        style: { width: 464 },
        canClick: false,
        Cell: (props) => (
          <div className="d-flex flex-wrap gap-1" style={{ maxWidth: 430 }}>
            {/* eslint-disable-next-line react/prop-types */}
            {props.value?.map((item, index) => (
              <PlatformStatusTag
                key={index}
                data={item}
                cancelInviteModalTarget="cancel-invite-user-modal"
                onClickCancel={() =>
                  // eslint-disable-next-line react/prop-types
                  _askToCancelInviteUser(props.cell.row.original?.email, item)
                }
              />
            ))}
          </div>
        ),
      },
    ],
    []
  );

  const _closeModal = (id) => {
    closeModal(id);
  };

  const _getUsers = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/list`
      );
      if (res?.data?.success) {
        setUsers(res?.data?.record);
      }
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    _getUsers();
  }, []);

  const _askToInviteUser = (email, role) => {
    setEmail(email);
    setRole(role);
  };

  const _askToCancelInviteUser = (email, platform) => {
    setEmail(email);
    setPlatform(platform);
  };

  const _inviteUser = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const invitePlatform = Object.keys(role).map((platform) => ({
        platform,
        role: role[platform].value,
      }));
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/invite`,
        { email, data: invitePlatform }
      );
      if (res?.data?.success) {
        _getUsers();
        _closeModal("confirm-invite-user-modal");
        toast.success("Invite sent successfully");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
      setErrCode(err?.response?.data?.message?.name);
    } finally {
      setIsSubmitting(false);
    }
  };

  const _cancelInviteUser = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/cancel-invite`,
        { email, system: platform?.name }
      );
      if (res?.data?.success) {
        _getUsers();
        _closeModal("cancel-invite-user-modal");
        toast.success("Invite cancelled");
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _goToDetail = (cell) => {
    const { email, first_name, last_name } = cell?.row?.original;
    navigate(`/user/${email}`, {
      state: { title: `User Details - ${first_name || ""} ${last_name || ""}` },
    });
  };

  return (
    <>
      <div
        className="bg-white border-bottom text-end px-3 px-md-4 px-lg-5"
        style={{ padding: 10 }}
      >
        <button
          className="btn btn-sm btn-outline-secondary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target={"#invite-user-modal"}
        >
          <PlusIcon />
          <span className="ms-2">User</span>
        </button>
        <InviteUserModal
          target="invite-user-modal"
          confirmTarget="confirm-invite-user-modal"
          errCode={errCode}
          onSubmit={_askToInviteUser}
        />
        <ConfirmInviteUserModal
          target="confirm-invite-user-modal"
          isSubmitting={isSubmitting}
          email={email}
          role={role}
          onConfirmed={_inviteUser}
        />
        <CancelInviteUserModal
          target="cancel-invite-user-modal"
          isSubmitting={isSubmitting}
          email={email}
          platform={platform}
          onConfirmed={_cancelInviteUser}
        />
      </div>
      <div className="card m-3 m-md-4 m-lg-5">
        <MainTable
          name="Users"
          columns={columns}
          data={users}
          isLoading={isLoading}
          onCellClick={_goToDetail}
        />
      </div>
    </>
  );
};

export default User;
