import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ChangePasswordModal from "components/ChangePasswordModal";
import Select from "components/shared/Select";
import { LockIcon, SaveIcon, UserIcon } from "components/svg";
import { DATE_TIME_FORMAT, REGEX_PATTERN, SYSTEM_ROLE } from "constant";
import { useUser } from "context/User";
import { closeModal, utcToLocal } from "helpers/functions";
import restServiceHelper from "helpers/restServiceHelper";
import moment from "moment-timezone";

const Account = () => {
  const navigator = useNavigate();
  const { user, updateUser } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: user?.timezone,
    label: user?.timezone,
  });
  const isAdmin = user?.role === SYSTEM_ROLE.ZI_ADMIN;

  useEffect(() => {
    const timezoneOptions = moment.tz
      .names()
      .map((timezone) => ({ value: timezone, label: timezone }));
    setTimezones(timezoneOptions);
  }, []);

  useEffect(() => {
    if (user.role === SYSTEM_ROLE.ZI_USER) {
      navigator(window.location.pathname, {
        replace: true,
      });
    }
  }, []); // eslint-disable-line

  const _changeTimeZone = (option) => {
    setSelectedTimezone(option);
  };

  const _updateAccountPromise = async (data) => {
    return axios.put(
      `${process.env.REACT_APP_API_ZEALINNOVATIONS}/account/basic-info`,
      data
    );
  };

  const _updateProfilePromise = async (data) => {
    return axios.put(
      `${process.env.REACT_APP_API_ZEALINNOVATIONS}/users/user-profile`,
      data
    );
  };

  const _submitForm = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const data = { timezone: selectedTimezone?.value };
      const formData = new FormData(event.target);
      for (const [name, value] of formData) {
        data[name] = value;
      }
      const { client_name, first_name, last_name, timezone } = data;
      const updatePromises = [];
      if (
        isAdmin &&
        (client_name !== user?.client_name || timezone !== user?.timezone)
      ) {
        updatePromises.push(
          _updateAccountPromise({
            account_id: user?.account_id,
            account_name: client_name,
            timezone,
          })
        );
      }
      if (first_name !== user?.first_name || last_name !== user?.last_name) {
        updatePromises.push(_updateProfilePromise({ first_name, last_name }));
      }

      const responses = await Promise.all(updatePromises);

      for (const res of responses) {
        if (!res?.data?.success) {
          toast.success("Something went wrong!");
          return;
        }
      }

      updateUser({ ...user, ...data });
      toast.success("Save successful");
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _isValidPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      toast.error("Password does not match!");
      return false;
    }
    if (!REGEX_PATTERN.USER_PASS.test(password)) {
      toast.error("Password does not valid.");
      return false;
    }
    return true;
  };

  const _changePassword = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const { target } = event;
      const data = {};
      const formData = new FormData(target);
      for (const [name, value] of formData) {
        data[name] = value;
      }
      const { password, confirmPassword } = data;
      if (!_isValidPassword(password, confirmPassword)) {
        return false;
      }
      // handle update password
      const res = await _updateProfilePromise({ password });
      if (res?.data?.success) {
        closeModal("change-password-modal");
        toast.success("Password updated");
        target.reset();
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form onSubmit={_submitForm}>
        <div
          className="bg-white border-bottom text-end px-3 px-md-4 px-lg-5"
          style={{ padding: 10 }}
        >
          {isSubmitting ? (
            <button
              type="button"
              className="btn btn-secondary btn-sm justify-content-center"
              style={{ width: 72, height: 30 }}
            >
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </button>
          ) : (
            <button type="submit" className="btn btn-outline-secondary btn-sm">
              <SaveIcon width={12} height={12} />
              <span className="ps-2">Save</span>
            </button>
          )}
        </div>
        <div className="p-3 p-md-4 p-lg-5 fw-500 account-wrapper">
          {isAdmin ? (
            <div className="card shadow-sm mb-4">
              <div className="card-header bg-transparent text-primary fw-semibold fs-6-lg">
                <span className="d-inline-block my-1">Basic Info</span>
              </div>
              <div className="card-body row g-3 g-md-5 align-items-end">
                <div className="col-12 col-md-4">
                  <label htmlFor="client_name" className="form-label">
                    Organization/Account Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="client_name"
                    name="client_name"
                    defaultValue={user?.client_name}
                    required
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Timezone</label>
                  <Select
                    defaultValue={selectedTimezone}
                    options={timezones}
                    onChange={_changeTimeZone}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Date Created</label>
                  <span className="d-block text-primary pt-3">
                    {utcToLocal(
                      user?.created_at * 1000,
                      DATE_TIME_FORMAT.COMMON_MONTH_DAY_YEAR
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="card shadow-sm">
            <div className="card-header bg-transparent text-primary fw-semibold fs-6-lg d-flex justify-content-between align-items-center">
              Profile
              <button
                type="button"
                className="btn btn-sm btn-outline-light"
                data-bs-toggle="modal"
                data-bs-target={"#change-password-modal"}
              >
                <LockIcon />
                <span className="ps-2">Change Password</span>
              </button>
            </div>
            <div className="card-body g-3 g-md-5 align-items-end position-relative">
              <div className="row align-items-end">
                <div className="col-12 col-md-4">
                  <div className="border rounded p-4 text-primary text-center">
                    <UserIcon width={75} height={63} />
                    <p className="mt-3 mb-0">{user?.email}</p>
                  </div>
                </div>

                <div className="col-12 col-md-4 pb-3">
                  <label htmlFor="first_name" className="form-label">
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    defaultValue={user?.first_name}
                    required
                  />
                </div>

                <div className="col-12 col-md-4 pb-3">
                  <label htmlFor="last_name" className="form-label">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    defaultValue={user?.last_name}
                    required
                  />
                </div>
              </div>

              <small
                className="text-muted position-absolute text-end p-4 me-4"
                style={{ top: 0, right: 0 }}
              >
                * Required
              </small>
            </div>
          </div>
        </div>
      </form>
      <ChangePasswordModal
        target="change-password-modal"
        isSubmitting={isSubmitting}
        onSubmit={_changePassword}
      />
    </>
  );
};

export default Account;
