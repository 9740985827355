/* eslint-disable react/prop-types */
export const DoubleChevronLeft = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
    transform: props.transform,
  };

  return (
    <svg style={svgStyles} viewBox="0 0 512 512">
      <g>
        <path
          d="M473.7,27.8c8.3,8.3,8.3,21.8,0,30.2L275.5,256.3l198.3,198.3c8.2,8.5,8,22-0.5,30.2c-8.3,8-21.4,8-29.6,0L230.3,271.3
		c-8.3-8.3-8.3-21.8,0-30.2L443.6,27.8C451.9,19.5,465.4,19.5,473.7,27.8L473.7,27.8z"
          fill={svgStyles.fill}
        />
        <path
          d="M281.7,27.8c8.3,8.3,8.3,21.8,0,30.2L83.5,256.3l198.3,198.3c8.2,8.5,8,22-0.5,30.2c-8.3,8-21.4,8-29.6,0L38.3,271.3
		c-8.3-8.3-8.3-21.8,0-30.2L251.6,27.8C259.9,19.5,273.4,19.5,281.7,27.8L281.7,27.8z"
          fill={svgStyles.fill}
        />
      </g>
    </svg>
  );
};
