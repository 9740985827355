import React from "react";
import { ChevronUpIcon, CircleCheckIcon, QualityIcon } from "components/svg";
import {
  PLAN_CODE,
  PLAN_DETAIL,
  PLAN_OPTION,
  PLAN_UPDATE_STATE,
} from "constant";
import PropTypes from "prop-types";

import EarlyAdopterPricingModal from "../EarlyAdopterPricingModal";
import EarlyAdopterPricingPopover from "../EarlyAdopterPricingPopover";
import ProductPriceLabel from "../ProductPriceLabel";

const PlanCard = (props) => {
  const { product, index, onShowTermOfUse } = props;
  const option = product.option.toLowerCase();
  const isCurrentPlan = product?.option === PLAN_OPTION.CURRENT;
  const isMarketerPlan = [
    PLAN_CODE.MARKETER_LITE,
    PLAN_CODE.MARKETER_MASTERY,
  ].includes(product?.plan);
  const EARLY_ADOPTER_PRICING_POPOVER_TARGET = "popover-cost-";

  const _renderProductActions = () => {
    if (isCurrentPlan) {
      return <QualityIcon />;
    }
    if (product?.plan === PLAN_CODE.PERSONAL) {
      return <div style={{ width: 100 }} />;
    }
    return (
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#${PLAN_UPDATE_STATE.TERM_OF_USE}-modal`}
        className="btn btn-sm btn-outline-light"
        onClick={(e) => onShowTermOfUse(product, e)}
      >
        <ChevronUpIcon
          transform={
            product?.option === PLAN_OPTION.DOWNGRADE && "rotate(180deg)"
          }
        />
        <span className="ms-2 text-capitalize">{option}</span>
      </button>
    );
  };

  return (
    <div
      key={index}
      className={`d-flex flex-column access-wrapper__plan ${
        isCurrentPlan && "access-wrapper__plan--active"
      }`}
      style={isMarketerPlan ? { flex: "3 3 45%" } : undefined}
    >
      <label className="small text-muted pb-2 text-capitalize">
        {isMarketerPlan
          ? "Better Value"
          : `
        ${option} ${isCurrentPlan ? "Plan" : "To"}`}
      </label>
      <div className="card flex-grow-1">
        <div className="card-header d-sm-flex gap-3 gap-sm-2 justify-content-sm-between  text-primary">
          <div className="mb-2 mb-sm-0">
            <p className="text-muted fs-8 mb-2">Plan</p>
            <label className="text-capitalize fw-semibold">
              {product?.name.replace("Zeal Stream ", "")}
            </label>
          </div>
          <span id={EARLY_ADOPTER_PRICING_POPOVER_TARGET + index}>
            <p className="text-muted fs-8 mb-2">Cost</p>
            <ProductPriceLabel product={product} />
            {!isCurrentPlan && product?.plan === PLAN_CODE.MARKETER_MASTERY && (
              <>
                <EarlyAdopterPricingPopover
                  popoverTarget={EARLY_ADOPTER_PRICING_POPOVER_TARGET + index}
                  modalTarget={PLAN_UPDATE_STATE.EARLY_ADOPTER_PRICING + index}
                />
                <EarlyAdopterPricingModal
                  target={PLAN_UPDATE_STATE.EARLY_ADOPTER_PRICING + index}
                  okBtnProps={
                    isCurrentPlan
                      ? undefined
                      : {
                          type: "button",
                          "data-bs-toggle": "modal",
                          "data-bs-target": `#${PLAN_UPDATE_STATE.TERM_OF_USE}-modal`,
                          title: (
                            <span className="text-capitalize">{option}</span>
                          ),
                          onClick: (e) => onShowTermOfUse(product, e),
                        }
                  }
                  okBtnIcon={
                    <ChevronUpIcon
                      transform={
                        product?.option === PLAN_OPTION.DOWNGRADE &&
                        "rotate(180deg)"
                      }
                    />
                  }
                />
              </>
            )}
          </span>
          <span className="flex-shrink-0 float-end float-sm-none">
            {_renderProductActions()}
          </span>
        </div>
        <div className="card-body p-4">
          {PLAN_DETAIL[product?.plan]?.features1?.map((feature, fIndex) => {
            const { title, content, divider } = feature;
            if (!(content || divider)) {
              return (
                <p key={fIndex} className="text-muted fs-8 mt-4 pt-1 mb-0">
                  {title}
                </p>
              );
            }
            return (
              <React.Fragment key={fIndex}>
                <p className="text-muted fs-8 mt-1 mb-2">{title}</p>
                {content && (
                  <ul className="list-unstyled mb-0 d-flex flex-wrap">
                    {content.map((item, index) => (
                      <li
                        key={index + "benefit"}
                        className="mb-2 access-wrapper__plan__list-item"
                      >
                        <CircleCheckIcon fill="#9889A4" />
                        <small className="ms-1 text-primary text-break">
                          {typeof item === "string" ? (
                            item
                          ) : (
                            <>
                              {item[0]}
                              <span className="text-muted ps-1">{item[1]}</span>
                            </>
                          )}
                        </small>
                      </li>
                    ))}
                  </ul>
                )}
                {divider && (
                  <hr className="border-bottom opacity-100 mt-2 pt-1 mb-4" />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  onShowTermOfUse: PropTypes.func.isRequired,
};

export default PlanCard;
