/* eslint-disable react/prop-types */
import React from "react";

export const CheckIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81353 7.21774L0.968732 4.37294L0 5.33485L3.81353 9.14838L12 0.96191L11.0381 0L3.81353 7.21774Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
