import React, { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PopoverBody, Spinner, UncontrolledPopover } from "reactstrap";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ActionModal from "components/shared/ActionModal";
import { CardOutlineIcon, HelpIcon } from "components/svg";
import { OVERAGE_CREDIT_PAYMENT_METHOD } from "constant";
import { useBilling } from "context/Billing";
import { useUser } from "context/User";
import PropTypes from "prop-types";

import CreditSliderBar from "../CreditSliderBar";

const stripeInputStyles = {
  color: "#421e5f",
  fontFamily: "Work Sans, system-ui",
  fontSize: "16px",
  fontWeight: 500,
};

function AddOveragesCreditsModal(props) {
  const { target } = props;
  const {
    overageCredits: creditList,
    isRequestMoreCredit,
    payOverageCredit,
    paymentMethod,
    getPlaidLinkToken,
    requestMoreOverageCredits,
  } = useBilling();
  const { user } = useUser();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE
  );
  const [selectedCredit, setSelectedCredit] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const anotherCardFormRef = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const isAllowACHPay = typeof paymentMethod?.ach === "object";

  useMemo(() => {
    if (creditList) {
      setSelectedCredit(creditList[0]);
    }
  }, [creditList]);

  const _changePaymentMethod = (event) => {
    const { name } = event.target;
    setSelectedPaymentMethod(name);
  };

  const _closeModal = () => {
    document.getElementById(`${target}-close`)?.click();
  };

  const _handlePayCreditResponse = (res) => {
    const { success } = res;
    if (success) {
      setSelectedPaymentMethod(OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE);
      toast.success("Overage Credits added successfully.");
      _closeModal();
    }
  };

  const _payOverageCredit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const data = selectedCredit || {};
      if (selectedPaymentMethod === OVERAGE_CREDIT_PAYMENT_METHOD.ACH) {
        const res = await payOverageCredit(selectedPaymentMethod, data, false);
        const { success, result } = res;
        if (success && result?.status) {
          toast.success("Your ACH pay is " + result.status);
          _closeModal();
        }
      }
      if (
        selectedPaymentMethod === OVERAGE_CREDIT_PAYMENT_METHOD.ANOTHER_CARD
      ) {
        const cardElement = elements.getElement(CardNumberElement);
        const res = await payOverageCredit(selectedPaymentMethod, data, false);
        const { success, result } = res;
        if (!success || !result?.client_secret) {
          toast.error("Something went wrong.");
          return;
        }
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          result.client_secret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: `${user?.first_name} ${user?.last_name}`,
                email: user?.email,
              },
            },
          }
        );
        if (error) {
          toast.error(error?.message);
        }
        if (paymentIntent) {
          const res = await payOverageCredit(selectedPaymentMethod, data, true);
          _handlePayCreditResponse(res);
        }
        return;
      }
      if (
        selectedPaymentMethod === OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE
      ) {
        const res = await payOverageCredit(selectedPaymentMethod, data, false);
        _handlePayCreditResponse(res);
      }
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!creditList) {
    return null;
  }

  return (
    <ActionModal
      target={target}
      title="Add Overages Credits"
      maxWidth={600}
      cancelBtnProps={{
        title: "Cancel",
      }}
      okBtnProps={{
        title: "Pay",
        "data-bs-toggle": undefined,
        "data-bs-target": undefined,
        type: "submit",
        form: "another-card-form",
        disabled: isSubmitting,
      }}
      okBtnIcon={
        isSubmitting ? (
          <Spinner
            size="sm"
            style={{ width: 12, height: 12, color: "currentcolor" }}
          />
        ) : (
          <CardOutlineIcon width={12} height={10} />
        )
      }
    >
      <div className="modal-body">
        <p className="text-primary fs-7 mb-5 pb-5">Select Amount</p>
        <CreditSliderBar
          list={creditList}
          selectedItem={selectedCredit}
          onSelectItem={setSelectedCredit}
        />
        {!isRequestMoreCredit ? (
          <>
            <button
              type="button"
              className="btn btn-link p-0 fs-7 fw-semibold"
              disabled={isRequestMoreCredit === undefined}
              onClick={requestMoreOverageCredits}
            >
              Need More?
            </button>
            <p className="text-muted fs-8 fw-500 pt-2 mt-1">
              Click above and a representative will reach out shortly to discuss
              bulk pricing and purchasing.
            </p>
          </>
        ) : (
          <div className="px-4 py-3 text-primary fs-8 fw-500 border border-secondary rounded">
            Thank You! Your request was submitted and a representative will
            reach out shortly.
          </div>
        )}
        <form
          id="another-card-form"
          className="another-card-form"
          ref={anotherCardFormRef}
          onSubmit={_payOverageCredit}
        >
          <p className="text-primary fs-7 mt-4 pt-2">Select Payment Method</p>
          <div className="d-flex justify-content-between align-items-center fw-500">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE}
                name={OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE}
                checked={
                  selectedPaymentMethod ===
                  OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE
                }
                onChange={_changePaymentMethod}
              />
              <label
                className="fs-7"
                htmlFor={OVERAGE_CREDIT_PAYMENT_METHOD.CARD_ON_FILE}
              >
                Use card on file
              </label>
            </div>
            {selectedCredit?.cc && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={OVERAGE_CREDIT_PAYMENT_METHOD.ANOTHER_CARD}
                  name={OVERAGE_CREDIT_PAYMENT_METHOD.ANOTHER_CARD}
                  checked={
                    selectedPaymentMethod ===
                    OVERAGE_CREDIT_PAYMENT_METHOD.ANOTHER_CARD
                  }
                  onChange={_changePaymentMethod}
                />
                <label
                  className="fs-7"
                  htmlFor={OVERAGE_CREDIT_PAYMENT_METHOD.ANOTHER_CARD}
                >
                  Use another card
                </label>
              </div>
            )}
            {selectedCredit?.ach && (
              <div className="d-flex align-items-end">
                <div className="form-check pe-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={OVERAGE_CREDIT_PAYMENT_METHOD.ACH}
                    name={OVERAGE_CREDIT_PAYMENT_METHOD.ACH}
                    checked={
                      selectedPaymentMethod ===
                      OVERAGE_CREDIT_PAYMENT_METHOD.ACH
                    }
                    disabled={!isAllowACHPay}
                    onChange={_changePaymentMethod}
                  />
                  <label
                    className={`fs-7 ${isAllowACHPay ? "" : "text-muted"}`}
                    htmlFor={OVERAGE_CREDIT_PAYMENT_METHOD.ACH}
                  >
                    ACH
                  </label>
                </div>
                <button
                  className="btn btn-link p-0"
                  id="ach-help-popover"
                  type="button"
                >
                  <HelpIcon width={20} height={20} />
                </button>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  offset={[0, 15]}
                  target="ach-help-popover"
                >
                  <PopoverBody>
                    <small className="fs-8 fw-500">
                      {isAllowACHPay ? (
                        "Once your account has been connected via ACH, this payment method will become available."
                      ) : (
                        <>
                          Once your account has been connected via ACH, this
                          payment method will become available. Click{" "}
                          <button
                            type="button"
                            className="btn btn-link text-reset text-decoration-underline p-0"
                            onClick={() => getPlaidLinkToken()}
                          >
                            here
                          </button>{" "}
                          to enable ACH payments
                        </>
                      )}
                    </small>
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            )}
            <span className="text-muted fs-7">* Required</span>
          </div>
          {selectedPaymentMethod ===
          OVERAGE_CREDIT_PAYMENT_METHOD.ANOTHER_CARD ? (
            <div className="row fs-6">
              <span className="col-12 col-md-6 mt-5 pe-md-4">
                <label htmlFor="card-number" className="form-label">
                  Card Number *
                </label>
                <CardNumberElement
                  name="number"
                  options={{
                    showIcon: true,
                    classes: { base: "form-control" },
                    style: { base: stripeInputStyles },
                  }}
                />
              </span>
              <span className="col-12 col-md-6 mt-5 ps-md-4">
                <label htmlFor="card-cvc" className="form-label">
                  CVC *
                </label>
                <CardCvcElement
                  name="cvc"
                  options={{
                    classes: { base: "form-control" },
                    style: { base: stripeInputStyles },
                  }}
                />
              </span>
              <span className="col-12 col-md-6 mt-5 pe-md-4">
                <label htmlFor="card-exp-date" className="form-label">
                  Expiration Date *
                </label>
                <CardExpiryElement
                  name="expiry"
                  options={{
                    classes: { base: "form-control" },
                    style: { base: stripeInputStyles },
                  }}
                />
              </span>
              <span className="col-12 col-md-6 mt-5 ps-md-4">
                <label htmlFor="billing-zip  " className="form-label">
                  Billing Zip *
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="billing-zip"
                  name="billing_zip"
                  required
                />
              </span>
            </div>
          ) : null}
        </form>
      </div>
    </ActionModal>
  );
}

AddOveragesCreditsModal.propTypes = {
  target: PropTypes.string.isRequired,
};
AddOveragesCreditsModal.defaultProps = {};

export default AddOveragesCreditsModal;
