import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const closeModal = (id) => {
  const modalToggle = document.getElementById(`${id}-close`);
  if (modalToggle) {
    modalToggle.click();
  }
};

export const localToUTC = (time, format) => {
  const utc = dayjs(time).utc();
  if (format) {
    return utc.format(format);
  }
  return utc;
};

export const utcToLocal = (time, format) => {
  const local = dayjs.utc(time).local();
  if (format) {
    return local.format(format);
  }
  return local;
};
