import React from "react";
import { INVITE_USER_STATUS, SYSTEMS } from "constant";
import PropTypes from "prop-types";

import { BinIcon, EcomLogoIcon, LaunchLogoIcon, StreamLogoIcon } from "../svg";

const PlatformStatusTag = (props) => {
  const { data, cancelInviteModalTarget, onClickCancel } = props;
  if (!data) {
    return null;
  }

  const _getBtnStyle = () => {
    const { status } = data;
    if (status === INVITE_USER_STATUS.INACTIVE.name) {
      return "badge text-light border-light pe-none";
    }

    if (status === INVITE_USER_STATUS.PENDING.name) {
      return "btn-outline-primary";
    }

    return "badge bg-primary text-white border-primary pe-none";
  };

  const _getLogo = () => {
    const { name } = data;
    if (name === SYSTEMS.ecom) {
      return <EcomLogoIcon width={48} height={16} />;
    }
    if (name === SYSTEMS.launch) {
      return <LaunchLogoIcon width={52} height={16} />;
    }
    return <StreamLogoIcon width={48} height={14} />;
  };

  const isPendingStatus = data.status === INVITE_USER_STATUS.PENDING.name;

  return (
    <div
      className="d-flex gap-1 align-items-center justify-content-between border rounded-pill ps-2"
      style={{ width: 210, padding: 5 }}
    >
      {_getLogo()}
      <button
        type="button"
        className={`d-flex align-items-center justify-content-center btn btn-sm rounded-pill text-capitalize fs-8 fw-500 ${_getBtnStyle()}`}
        style={{ width: 137, paddingTop: 3, paddingBottom: 3 }}
        {...(isPendingStatus && {
          "data-bs-toggle": "modal",
          "data-bs-target": `#${cancelInviteModalTarget}`,
          onClick: onClickCancel,
        })}
      >
        <span className="text-capitalize">{data.status.toLowerCase()}</span>
        {isPendingStatus && (
          <span
            className="ps-2"
            style={{ marginTop: -2 }}
            data-bs-toggle="modal"
            data-bs-target={"#cancel-invite-user-modal"}
          >
            <BinIcon width={8} height={11} />
          </span>
        )}
      </button>
    </div>
  );
};

PlatformStatusTag.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.oneOf([
      SYSTEMS.innovations,
      SYSTEMS.stream,
      SYSTEMS.ecom,
      SYSTEMS.launch,
    ]),
    status: PropTypes.oneOf([
      INVITE_USER_STATUS.ACTIVE.name,
      INVITE_USER_STATUS.INACTIVE.name,
      INVITE_USER_STATUS.PENDING.name,
    ]),
  }).isRequired,
  cancelInviteModalTarget: PropTypes.string.isRequired,
  onClickCancel: PropTypes.func.isRequired,
};

export default PlatformStatusTag;
