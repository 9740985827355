import React from "react";
import { Card, CardBody, CardHeader, Input } from "reactstrap";
import {
  AmexIcon,
  CardIcon,
  DinersCardIcon,
  DiscorvercardIcon,
  EditIcon,
  JCBCardIcon,
  MastercardIcon,
  UnionpayIcon,
  VisaIcon,
} from "components/svg";
import { ACTION_TYPE, useBilling } from "context/Billing";
import PropTypes from "prop-types";

const CARD_BRAND_ICON = {
  amex: <AmexIcon width={25} height={17} />,
  diners: <DinersCardIcon width={25} height={17} />,
  discover: <DiscorvercardIcon width={25} height={17} />,
  jcb: <JCBCardIcon width={25} height={18} />,
  mastercard: <MastercardIcon width={25} height={16} />,
  visa: <VisaIcon width={25} height={17} />,
  unionpay: <UnionpayIcon width={25} height={17} />,
};

const PAYMENT_METHOD_TITLE = {
  card: "Credit Card",
};

function PayCreditCard(props) {
  const { data, name } = props;
  const { actionType, defaultPaymentId, setDefaultPaymentMethod } =
    useBilling();

  if (!data) {
    return null;
  }

  const { id } = data;

  return (
    <Card className="h-100">
      <CardHeader className="d-flex justify-content-between align-items-center bg-transparent text-primary fw-semibold">
        {PAYMENT_METHOD_TITLE[data.type]}
        <span className="text-right">
          <button
            className="btn btn-sm btn-outline-light ms-3"
            data-bs-toggle="modal"
            data-bs-target={"#update-payment-method-modal"}
          >
            <EditIcon width={12} height={12} />
            <span className="ps-1">Edit</span>
          </button>
        </span>
      </CardHeader>
      <CardBody className="d-flex flex-column justify-content-between p-4 fw-500 text-primary">
        <div className="d-flex gap-2 align-items-center justify-content-between">
          <div className="d-flex gap-2">
            {CARD_BRAND_ICON[data.cc_name] || (
              <CardIcon width={25} height={20} />
            )}
            <span>
              <p className="fw-semibold mb-2">
                <span className="text-capitalize">{data.cc_name}</span> ending
                in {data.last_4}
              </p>
              <small className="text-muted fs-8">
                Expiry {data.expiration_month}/{data.expiration_year}
              </small>
            </span>
          </div>
        </div>
        <div className="form-check mt-5">
          <Input
            type="checkbox"
            id={`card-default-${name}`}
            name={`card-default-${name}`}
            defaultChecked={data.is_default}
            checked={defaultPaymentId === id}
            disabled={actionType === ACTION_TYPE.DEFAULT_PAYMENT_METHOD_UPDATE}
            onChange={() => setDefaultPaymentMethod(id)}
          />
          <label className="" htmlFor={`card-default-${name}`}>
            Default payment method
          </label>
        </div>
      </CardBody>
    </Card>
  );
}

PayCreditCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default PayCreditCard;
