import React from "react";
import { Input, Label, Spinner } from "reactstrap";
import ActionModal from "components/shared/ActionModal";
import { EditIcon, SaveIcon } from "components/svg";
import { ACTION_TYPE, useBilling } from "context/Billing";
import PropTypes from "prop-types";

function BillingEmailCard(props) {
  const { email, onEdit } = props;
  const { actionType } = useBilling();
  const isSubmitting = actionType === ACTION_TYPE.BILLING_EMAIL_UPDATE;

  const _submitForm = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    for (const [name, value] of formData) {
      if (name === "email") {
        onEdit(value);
      }
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-end">
        <div className="flex-grow-1">
          <p className="text-primary fw-semibold mb-1">Billing Email</p>
          <small className="text-muted fs-8">{email}</small>
        </div>
        <button
          type="button"
          className="btn btn-sm btn-outline-light"
          data-bs-toggle="modal"
          data-bs-target="#editting-billing-email"
        >
          <EditIcon width={12} height={12} />
          <span className="ps-1">Edit</span>
        </button>
      </div>
      <ActionModal
        target={"editting-billing-email"}
        title="Edit Billing Email"
        maxWidth={600}
        isOpen={isSubmitting}
        cancelBtnProps={{
          title: "Cancel",
        }}
        okBtnProps={{
          title: "Save",
          "data-bs-toggle": undefined,
          "data-bs-target": undefined,
          type: "submit",
          form: "editting-billing-email-form",
          disabled: isSubmitting,
        }}
        okBtnIcon={
          isSubmitting ? (
            <Spinner
              size="sm"
              style={{ width: 12, height: 12, color: "currentcolor" }}
            />
          ) : (
            <SaveIcon width={12} height={10} />
          )
        }
      >
        <div className="modal-body">
          <p className="text-muted fw-500">
            This is the email that you will receive your monthly receipts to.
          </p>
          <form id="editting-billing-email-form" onSubmit={_submitForm}>
            <Label>Email</Label>
            <Input
              autoFocus
              required
              type="email"
              name="email"
              className="w-50"
              defaultValue={email}
            />
          </form>
        </div>
      </ActionModal>
    </div>
  );
}

BillingEmailCard.propTypes = {
  email: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default BillingEmailCard;
