/* eslint-disable react/prop-types */
import React from "react";

export const UserIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyles}
      fill="none"
    >
      <g clipPath="url(#clip0_669_101)">
        <path
          d="M25.0001 9.22433C20.1917 9.22433 16.2793 13.1363 16.2793 17.9451C16.2793 22.7535 20.1917 26.6655 25.0001 26.6655C29.8085 26.6655 33.7209 22.7535 33.7209 17.9451C33.7209 13.1363 29.8085 9.22433 25.0001 9.22433Z"
          fill={svgStyles.fill}
        />
        <path
          d="M25 0C11.2148 0 0 11.2148 0 25C0 31.3782 2.40211 37.2047 6.34727 41.6264C7.19223 37.9162 9.13124 34.531 11.9625 31.8939C13.9496 30.043 16.2746 28.6579 18.7878 27.7966C15.5224 25.7301 13.3495 22.0867 13.3495 17.9451C13.3495 11.5208 18.576 6.29463 25 6.29463C31.424 6.29463 36.6501 11.5208 36.6501 17.9451C36.6501 22.0848 34.4791 25.7271 31.216 27.7939C33.9382 28.7262 36.4334 30.2742 38.5281 32.3689C41.0873 34.9281 42.8543 38.1451 43.6508 41.6279C47.5975 37.2063 50 31.3789 50 25C50 11.2148 38.7852 0 25 0Z"
          fill={svgStyles.fill}
        />
        <path
          d="M8.89673 44.1063C13.2501 47.7814 18.8703 50 25.0001 50C31.1299 50 36.7501 47.781 41.1035 44.1063C40.7025 40.464 39.0657 37.0495 36.4568 34.4406C33.3959 31.3797 29.3271 29.694 25.0001 29.694C16.7588 29.694 9.79128 35.981 8.89673 44.1063Z"
          fill={svgStyles.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_669_101">
          <rect width="50" height="50" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
