import React, { useContext } from "react";

const BillingContext = React.createContext();

const useBilling = () => {
  const c = useContext(BillingContext);
  if (!c) throw new Error("useBilling must be used in BillingProvider");
  return c;
};

const ACTION_TYPE = {
  PAYMENT_ACH_GET_TOKEN: "PAYMENT_ACH_GET_TOKEN",
  PAYMENT_ACH_MANUAL_VERIFY: "PAYMENT_ACH_MANUAL_VERIFY",
  PAYMENT_ACH_DELETE: "PAYMENT_ACH_DELETE",
  PAYMENT_ACH_UPDATE: "PAYMENT_ACH_UPDATE",
  PAYMENT_CARD_UPDATE: "PAYMENT_CARD_UPDATE",
  BILLING_EMAIL_UPDATE: "BILLING_EMAIL_UPDATE",
  DEFAULT_PAYMENT_METHOD_UPDATE: "DEFAULT_PAYMENT_METHOD_UPDATE",
};

export default BillingContext;
export { useBilling, ACTION_TYPE };
