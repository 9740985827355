import React, { useEffect } from "react";
import CreditCardForm from "components/shared/CreditCardForm";
import { PLAN_UPDATE_STATE, STRING } from "constant";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import {
  BoxIcon,
  CardOutlineIcon,
  CheckIcon,
  CloseIcon,
  EnterIcon,
} from "../svg";

const UpdatePlanModal = (props) => {
  const { type, product, isSubmitting, onConfirmed, onSubmitCard } = props;

  useEffect(() => {
    if (type === PLAN_UPDATE_STATE.SUCCESS) {
      document
        .getElementById(`${PLAN_UPDATE_STATE.ASK_TO_CONFIRM}-close`)
        ?.click();
      document
        .getElementById(`${PLAN_UPDATE_STATE.ADD_CREDIT_CARD}-close`)
        ?.click();
    }
  }, [type]);

  if (!product) {
    return (
      <ActionModal
        target={`${PLAN_UPDATE_STATE.TERM_OF_USE}-modal`}
        title=""
      ></ActionModal>
    );
  }

  const planName = product?.plan?.toLowerCase();

  const priceFrequency = product?.price
    ? `$${product?.price}/${product?.frequency.slice(0, -2)}`.toLowerCase()
    : "free";

  return (
    <>
      <ActionModal
        target={`${PLAN_UPDATE_STATE.TERM_OF_USE}-modal`}
        title={product?.option?.toLowerCase()}
      >
        <div className="modal-body">
          <span className="d-flex gap-2 text-muted pt-4 mb-2">
            <BoxIcon width={20} height={20} />
            <h6 className="text-primary fs-6-lg fw-semibold text-capitalize">
              {`${planName} ${product?.option?.toLowerCase()}`}
            </h6>
          </span>
          <p className="text-primary fw-500 lh-lg text-break">
            {STRING[`${product?.option}_TERM`]}
            <br />
            <br />
            {STRING.UPGRADE_TERM_SUBMIT.replace(
              "{0}",
              product?.frequency?.toLowerCase()
            ).replace("{1}", priceFrequency)}
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            data-bs-dismiss="modal"
            data-bs-target={`#${PLAN_UPDATE_STATE.TERM_OF_USE}-modal`}
            aria-label="Close"
            className="btn btn-sm btn-outline-light d-flex align-items-center"
          >
            <CloseIcon width={12} height={12} />
            <span className="ms-2">Cancel</span>
          </button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target={`#${
              onSubmitCard
                ? PLAN_UPDATE_STATE.ADD_CREDIT_CARD
                : PLAN_UPDATE_STATE.ASK_TO_CONFIRM
            }`}
            className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
          >
            <EnterIcon width={12} height={12} />
            <span className="ms-2">Submit</span>
          </button>
        </div>
      </ActionModal>
      {/** Add Credit card here if user is in Personal plan */}
      <ActionModal
        target={PLAN_UPDATE_STATE.ADD_CREDIT_CARD}
        title={`Purchase ${planName} Plan`}
        cancelBtnProps={{ title: "Cancel", disabled: isSubmitting }}
        okBtnProps={{
          "data-bs-toggle": undefined,
          "data-bs-target": PLAN_UPDATE_STATE.ASK_TO_CONFIRM,
          title: "Pay",
          type: "submit",
          form: "add-new-card",
          disabled: isSubmitting,
        }}
        okBtnIcon={
          isSubmitting ? (
            <div
              className="spinner-border spinner-border-sm text-white"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <CardOutlineIcon width={12} height={10} />
          )
        }
      >
        <div className="modal-body">
          <p className="fs-7 fw-500 text-muted">
            In order to upgrade to the{" "}
            <span className="text-capitalize">{planName}</span> Plan you will
            need to add a credit card and complete your purchase. You will be
            charge <b className="text-primary">${product?.price}</b> today and
            every 30 days after today.
          </p>
          <CreditCardForm formId="add-new-card" onSubmit={onSubmitCard} />
        </div>
      </ActionModal>
      <ActionModal
        target={`${PLAN_UPDATE_STATE.ASK_TO_CONFIRM}`}
        title="Are you sure?"
      >
        <div className="modal-body">
          <p className="text-primary fw-500 lh-lg">
            By clicking yes, you are confirming the activation of your new{" "}
            <span className="text-capitalize">{planName}</span> plan and agree
            to its monthly subscription cost of {priceFrequency}.
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            data-bs-dismiss="modal"
            id={`${PLAN_UPDATE_STATE.ASK_TO_CONFIRM}-close`}
            data-bs-target={`#${PLAN_UPDATE_STATE.ASK_TO_CONFIRM}`}
            aria-label="Close"
            className="btn btn-sm btn-outline-light d-flex align-items-center"
            disabled={isSubmitting}
          >
            <CloseIcon width={12} height={12} />
            <span className="ms-2">Cancel</span>
          </button>
          <button
            type="button"
            data-bs-target={`#${PLAN_UPDATE_STATE.ASK_TO_CONFIRM}`}
            className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
            disabled={isSubmitting}
            onClick={onConfirmed}
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <CheckIcon width={12} height={12} />
            )}
            <span className="ms-2">Yes</span>
          </button>
        </div>
      </ActionModal>
    </>
  );
};
UpdatePlanModal.propTypes = {
  type: PropTypes.string,
  product: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  onConfirmed: PropTypes.func.isRequired,
  onSubmitCard: PropTypes.func,
};
UpdatePlanModal.defaultProps = {
  type: undefined,
  product: undefined,
  isSubmitting: false,
  onSubmitCard: undefined,
};
export default UpdatePlanModal;
