import React from "react";
import { SYSTEM_LOGO } from "constant";
import PropTypes from "prop-types";

import ActionModal from "../shared/ActionModal";
import { CheckIcon, CloseIcon } from "../svg";

const ConfirmInviteUserModal = (props) => {
  const { target, isSubmitting, email, role, onConfirmed } = props;

  const isValidData = typeof role === "object" && email;

  return (
    <ActionModal
      maxWidth={600}
      target={target}
      title={
        <>
          Are <span className="text-lowercase">you sure?</span>
        </>
      }
    >
      {isValidData ? (
        <form onSubmit={onConfirmed}>
          <div
            className="modal-body text-start text-primary fw-500"
            style={{ lineHeight: "19px" }}
          >
            {
              "By clicking 'Yes' below, you are confirming that you want the following invitations sent to "
            }
            <b>{email}:</b>
            <div className="d-flex gap-5 align-items-end mt-5">
              {Object.keys(role).map((system, index) => (
                <div key={index}>
                  {SYSTEM_LOGO[system]}
                  <br />
                  <span
                    className="btn btn-outline-primary btn-sm rounded-pill px-4"
                    style={{ marginTop: 10 }}
                  >
                    {role[system].label} Invite
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="reset"
              data-bs-dismiss="modal"
              data-bs-target={`#${target}`}
              aria-label="Close"
              className="btn btn-sm btn-outline-light d-flex align-items-center"
              disabled={isSubmitting}
            >
              <CloseIcon width={12} height={12} />
              <span className="ms-2">Cancel</span>
            </button>
            <button
              type="submit"
              data-bs-target={`#${target}`}
              className="btn btn-sm btn-secondary d-flex align-items-center ms-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <CheckIcon width={12} height={12} />
              )}
              <span className="ms-2">Yes</span>
            </button>
          </div>
        </form>
      ) : null}
    </ActionModal>
  );
};

ConfirmInviteUserModal.propTypes = {
  target: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.objectOf(PropTypes.any),
  onConfirmed: PropTypes.func.isRequired,
};
ConfirmInviteUserModal.defaultProps = {
  role: undefined,
};
export default ConfirmInviteUserModal;
