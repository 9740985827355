/* eslint-disable react/prop-types */
import React from "react";

export const EditIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73505 0C9.57337 0 9.41167 0.061874 9.28851 0.18503L8.02538 1.44817L7.1323 2.34125L0 9.47354V11.9998H2.52628L11.8148 2.71131C12.0617 2.46436 12.0617 2.06454 11.8148 1.81823L10.1816 0.18503C10.0584 0.061874 9.89674 0 9.73505 0ZM9.73505 1.52465L10.4752 2.26477L9.65857 3.08137L8.91845 2.34125L9.73505 1.52465ZM8.02538 3.23433L8.7655 3.97445L2.00326 10.7367H1.26314V9.99656L8.02538 3.23433Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
